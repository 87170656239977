import { GenericService } from '../../App/services/generic'

import { Event } from '../../../../cz.samaya.api/src/_api-models/index'

export class CoursesService extends GenericService<Event> {
    constructor() { super( '/events/item/' ) }

    public parse( item: Event ) {

        item.start = new Date( item.start as unknown as string )
        item.end = new Date( item.end as unknown as string )

        item.modified = new Date( item.modified as unknown as string )

        /*if( item.attachments ) {
            item.attachments.forEach( attn => {
                if( attn.type === 'image' ) {
                    const dot = attn.filename.lastIndexOf( '.' )
                    const eee = attn.filename.split( '.' ).pop() || ''
                    const fn = ( attn.filename.indexOf('https://') === -1 ? '/assets' : '' ) + attn.filename
                    const fnt =
                        ( attn.filename.indexOf('https://') === -1 ? '/assets' : '' )
                        + ( attn.thumb ? attn.filename.substring( 0, dot ) + '_thumb.' + eee : attn.filename )

                    item.images.push({
                        type: [ 'mkv', 'avi', 'mp4' ].find( (v ) => v === eee ) || 'image',
                        filename: fn,
                        name: attn.name || '',
                        thumb: fnt
                    })
                } else {
                    item.attns.push(attn)
                }
            } )
        }*/
        return item
    }
}
