import React, { forwardRef } from 'react'
import { Button, Col } from 'react-bootstrap'
import { FaPen } from 'react-icons/fa'

import { useAuth } from '../../../Auth'

type BlockProps = {
    title: string
    subtitle?: string
    imgUrl?: string
    children: React.ReactNode
    editLink?: string
}

export const Block = forwardRef<HTMLDivElement, BlockProps>(( { title, subtitle, imgUrl, children, editLink }: BlockProps, ref ) => {

    const auth = useAuth()

    return(
        <Col ref={ ref } lg={ 6 } xl={ 4 }>
            <h4 className="text-center bg-primary text-white lead">
                { auth.isAuthenticated && typeof editLink !== 'undefined' && <Button variant="light" className="btn position-absolute" style={{ right: '5%', zIndex: '999' }} href={ editLink }><FaPen /></Button> }
                <div>{ title }</div>
                { subtitle && <small className="text-right">{ subtitle }</small> }
            </h4>
            { imgUrl && <div className="img-box"><div>
                <img src={ imgUrl } alt={ title } className="w-100" />
            </div></div> }
            { children }
        </Col>
    )
} )