import React, { useState, createContext, useCallback, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { AppStatusComponentProps, ComponentWithChildrenProps, useHttpRequestContext, useQueryString, useTimeout, Storage } from '../../Common'

import { AuthContext as AuthContextType, AuthService, AuthData, User, AuthOperResult } from '../types'
import { authDataStorageFactory } from '../utils'

export const AUTH_CONFIG = {
    pageHeading: undefined as React.ElementType<ComponentWithChildrenProps & {title?: string}> | undefined,
    pageStatus: undefined as React.ElementType<AppStatusComponentProps> | undefined,
    viewWrapper: undefined as React.FC<ComponentWithChildrenProps> | undefined,
    loginHome: undefined as string | undefined
}

type AuthContextProviderProps<T extends User> = {
    authService: AuthService<T>,
    children: React.ReactNode
    registerLink?: React.ReactNode
    loginHome?: string
    pageHeading?: React.ElementType<ComponentWithChildrenProps & {title?: string}>
    pageStatus?: React.ElementType<AppStatusComponentProps>
    viewWrapper?: React.FC<ComponentWithChildrenProps>
    storage?: Storage
}

const dummyContext: AuthContextType<User> = {
    isAuthenticated: false,
    getUser: () => undefined,
    triggerUserUpdate: () => {},
    hasRole: () => false,
    // eslint-disable-next-line @typescript-eslint/require-await
    login: async () => ({ success: false, status: 501, message: 'This is dummy service, actual Authentication service not initialized.' }),
    // eslint-disable-next-line @typescript-eslint/require-await
    pwdReset: async () => ({ success: false, status: 501, message: 'This is dummy service, actual Authentication service not initialized.' }),
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    logout: () => {},
    getAuthHeader: () => undefined
}

export const AuthContext = createContext<AuthContextType<User>>( dummyContext )

export const AuthContextProvider = <T extends User>( { authService, children, registerLink, pageHeading, pageStatus, viewWrapper, loginHome, storage }: AuthContextProviderProps<T> ) => {

    const { getPersistentAuthData, setPersistentAuthData, removePersistentAuthData } = authDataStorageFactory( storage )

    if( pageHeading ) AUTH_CONFIG.pageHeading = pageHeading
    if( pageStatus ) AUTH_CONFIG.pageStatus = pageStatus
    if( viewWrapper ) AUTH_CONFIG.viewWrapper = viewWrapper
    if( loginHome ) AUTH_CONFIG.loginHome = loginHome

    const intl = useIntl()

    const navigate = useNavigate()

    const httpRequestContext = useHttpRequestContext()
    const [ authData, setAuthData ] = useState<AuthData<T> | undefined>( getPersistentAuthData() )
    const [ isAuthenticated, setAuthenticated ] = useState<boolean>( authService.checkAuthenticated( authData ) > 0 )

    const getUser = () => isAuthenticated ? authService.getUser( authData ) : undefined

    const hasRole = useCallback( 
        ( roles?: string[] | string ) => isAuthenticated && authService.hasRole( authData, roles )
        , [ authService, authData, isAuthenticated ] 
    )

    const logout = useCallback( ( goHome = false ) => {
        removePersistentAuthData()
        setAuthData( undefined )
        setAuthenticated( false )
        httpRequestContext.cache.clear()
        if( authService.logout ) authService.logout()
        if( goHome ) navigate( '/')
    }, [ authService, navigate ] )

    const processAuthData = useCallback( ( newAuthData: AuthOperResult<T> ) => {
        if( newAuthData.success && newAuthData.data ) {
            setPersistentAuthData( newAuthData.data )
            setAuthData( newAuthData.data )
            setAuthenticated( authService.checkAuthenticated( newAuthData.data ) > 0 )
        }
        else if( isAuthenticated ) logout()
        return newAuthData
    }, [ authService, isAuthenticated, logout ])

    const login = useCallback( async ( username: string, pwd: string ): Promise<AuthOperResult<T>> => {
        const loginResult = await authService.login( username, pwd )
        if( loginResult.status === 500 ) 
            loginResult.message = intl.formatMessage({ description: 'auth.service.login.error', defaultMessage: 'Unknwon error when processing login request.' })
        return processAuthData( loginResult )
    }, [ authService, processAuthData, intl ] )

    const reAuthenticate = useCallback( async () => {
        const reAuthResult = await authService.reAuthenticate( authData )
        if( reAuthResult.status === 403 ) 
            reAuthResult.message = intl.formatMessage({ description: 'auth.service.re-auth.none', defaultMessage: 'No re-authentication token exists, cannot re-authenticate.' })
        else if( reAuthResult.status === 500 )
            reAuthResult.message = intl.formatMessage({ description: 'auth.service.re-auth.error', defaultMessage: 'Unknwon error when processing re-authentication request.' })
        return processAuthData( reAuthResult )
    }, [ authService, authData, processAuthData, intl ] )

    const triggerUserUpdate = useCallback( () => reAuthenticate(), [ reAuthenticate ] )

    const pwdReset = useCallback( async ( username: string ) => {
        if( authService.pwdReset ) {
            const pwdResetResult = await authService.pwdReset( username )
            if( pwdResetResult.status === 500 )
                pwdResetResult.message = intl.formatMessage({ description: 'auth.service.pwd-reset.error', defaultMessage: 'Unknwon error when processing password reset request.'  })
            return pwdResetResult
        }
        else return { success: false, status: 501 /* Not implemented */, message: intl.formatMessage({ description: 'auth.service.pwd-reset.not-implemented', defaultMessage: 'Password reset is not implemented.'  }) }
    }, [ authService, intl ] )

    const pwdChange = useCallback( async ( pwd: string ) => {
        if( authService.pwdChange ) {
            const pwdChangeResult = await authService.pwdChange( authData, pwd )
            if( pwdChangeResult.status === 401 )
                pwdChangeResult.message = intl.formatMessage({ description: 'auth.service.pwd-change.error', defaultMessage: 'Not authenticated, cannot change password.' })
            else if( pwdChangeResult.status === 500 )
                pwdChangeResult.message = intl.formatMessage({ description: 'auth.service.pwd-change.error', defaultMessage: 'Unknwon error when processing password change.' })
            return pwdChangeResult
        }
        else return { success: false, status: 501 /* Not implemented */, message: intl.formatMessage({ description: 'auth.service.pwd-change.not-implemented', defaultMessage: 'Password change is not implemented.'  }) }
    }, [ authService, authData, intl ] )

    const oneTimeToken = useQueryString( 'token' )
    const getAuthHeader = useCallback( () => {
        if( authService.checkAuthenticated( authData ) ) return authService.getAuthHeader( authData )
        else {
            logout()
            return undefined
        }
    }, [ authService, authData, oneTimeToken, logout ] )

    useTimeout( 
        reAuthenticate, authService.getReAuthenticationInterval( authData )
        , [ authService, authData ] 
    )

    // configure request context to use authentication
    useEffect( () => {
        httpRequestContext.setOptions({ ...httpRequestContext.options, getAuthHeader })
    }, [ getAuthHeader ] )

    return(
        <AuthContext.Provider value={ { 
            isAuthenticated, 
            getUser, 
            triggerUserUpdate,
            hasRole, 
            login, 
            // !!! Do not publish callback if not present in service
            pwdReset: authService.pwdReset ? pwdReset : undefined, 
            pwdChange: authService.pwdChange ? pwdChange : undefined,
            registerLink,
            logout, 
            getAuthHeader 
        } }>
            { children }
        </AuthContext.Provider>
    )
}
