import { useEffect, useState } from 'react'

const MatchMediaMock = {
    matches: false,
    addEventListener: ( type: string, listener: (this: MediaQueryList, ev: MediaQueryListEvent) => any ) => {},
    removeEventListener: ( type: string, listener: (this: MediaQueryList, ev: MediaQueryListEvent) => any ) => {}
}

export const useMatchMedia = ( mediaQuery: string ): boolean => {

    const mediaMatch = typeof window !== 'undefined' ? window.matchMedia( mediaQuery ) : MatchMediaMock
    const [ matches, setMatches ] = useState( mediaMatch.matches )

    useEffect(() => {
        const handler = ( e: MediaQueryListEvent ) => setMatches( e.matches )
        mediaMatch.addEventListener( 'change', handler )
        return () => mediaMatch.removeEventListener( 'change', handler )
    }, [ mediaQuery ])

    return matches
}