import React, { forwardRef } from 'react'
import { Form as BSForm, InputGroup } from 'react-bootstrap'

import { useOnChange } from '../../hooks/useOnChange'
import { FormContextType } from '../../types'

export type ComboProps = {
    controlId: string
    children: React.ReactNode
    className?: string
    required?: boolean
    disabled?: boolean
    readOnly?: boolean
    isValid?: boolean | { ( form: FormContextType ): boolean }
    isInvalid?: boolean | { ( form: FormContextType ): boolean }
    placeholder?: string
    invalidMsg?: string
    prefix?: string | React.ReactNode
    suffix?: string | React.ReactNode
}

export const Combo = forwardRef<HTMLSelectElement, ComboProps>( ({ controlId, children, placeholder, className, invalidMsg, disabled, isValid, isInvalid, prefix, suffix, ...props }: ComboProps, ref ) => {

    const { form, onChange } = useOnChange( controlId, 'value' )

    return(<BSForm.Group controlId={ controlId } className={ [ 'mb-3', className ].join( ' ' ) }>
        <InputGroup>
            { typeof prefix === 'string' && <InputGroup.Text>{ prefix }</InputGroup.Text> }
            { typeof prefix === 'object' && prefix }
            <BSForm.Select 
                ref={ ref }
                { ...props }
                value={ form ? form.data[ controlId ] as string : '' }
                onChange={ onChange }
                required
                disabled={ disabled || form?.processing }
                isValid={ form && form.validated && typeof isValid === 'function' ? isValid( form ) : typeof isValid === 'boolean' ? isValid : undefined }
                isInvalid={ form && form.validated && typeof isInvalid === 'function' ? isInvalid( form ) : typeof isInvalid === 'boolean' ? isInvalid : undefined }
            >             
                <>
                    { placeholder && <option value='' disabled>{ placeholder } </option> }
                    { children }
                </>
            </BSForm.Select>
            { typeof suffix === 'string' && <InputGroup.Text>{ suffix }</InputGroup.Text> }
            { typeof suffix === 'object' && suffix }
            { invalidMsg && <BSForm.Control.Feedback type='invalid'>{ invalidMsg }</BSForm.Control.Feedback> }
        </InputGroup>
    </BSForm.Group>)
} )