import React, { forwardRef, useRef, useState } from 'react'
import { Button } from 'react-bootstrap'
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs'

import { Text, TextProps } from '../Text'

export const Password = forwardRef<HTMLInputElement, TextProps>( ( props: TextProps, fwdRef ) => {

    const [ showPwd, setShowPwd ] = useState( false )
    const ref = useRef<HTMLInputElement>( null )
    React.useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(
        fwdRef,
        () => ref.current
    )

    const onShowPwd = () => {
        setShowPwd( !showPwd )
        if( ref.current ) {
            const input = ref.current, 
                end = input.value.length
            // move curosr to the end of the text
            input.setSelectionRange( end, end )
            input.focus()
        }
    }

    return <Text 
        { ...props }
        ref={ ref }
        type={ showPwd ? 'text' : 'password' }
        suffix={ <Button variant='outline-secondary' onClick={ onShowPwd } tabIndex={ -1 }>{ !showPwd && <BsEyeFill></BsEyeFill>}{ showPwd && <BsEyeSlashFill></BsEyeSlashFill>}</Button> }
        required
    /> 
} )