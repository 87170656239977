import React, { forwardRef, useRef } from 'react'

import monaco from 'monaco-editor'
import Editor, { loader } from '@monaco-editor/react'

import { useOnChange } from '../../hooks/useOnChange'

type MonacoProps = {
    controlId: string
    language: string
    height?: number
    width?: number
}

export const Monaco = ( { controlId, ...props }: MonacoProps ) => {

    const editorRef = useRef<monaco.editor.IStandaloneCodeEditor>()
    
    const { form, onChange } = useOnChange( controlId, 'literal' )

    function handleEditorDidMount( editor: monaco.editor.IStandaloneCodeEditor ) {
        editorRef.current = editor;
    }

    return(<Editor
        defaultValue={ form?.data[ controlId ] as string || '' }
        onMount={ handleEditorDidMount }
        onChange={ value => onChange( value || '' ) }
        { ...props }
    />)
}