import React, { createContext, useCallback, useState } from 'react'

import { Notification, NotificationService } from '../types'

type NotificationContext = NotificationService

type NotificationContextProviderProps = {
    notificationService: NotificationService
    children: React.ReactNode
}

const dummyContext = {
    getNotifications: () => [],
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    addNotification: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    removeNotification: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    removeAllNotifications: () => {}
}

export const NotificationContext = createContext<NotificationContext>( dummyContext )

export const NotificationContextProvider = ( { notificationService, children }: NotificationContextProviderProps  ) => {

    const [ counter, setCounter ] = useState( 0 )

    const addNotification = useCallback( ( notification: Notification ) => {
        notificationService.addNotification( notification )
        setCounter( counter + 1 )
    }, [ notificationService, counter ] )

    const removeNotification = useCallback( ( key: number ) => {
        notificationService.removeNotification( key )
        setCounter( counter + 1 )
    }, [ notificationService, counter ] )

    const removeAllNotifications = useCallback( () => {
        notificationService.removeAllNotifications()
        setCounter( 0 )
    }, [ notificationService ] )

    return(<NotificationContext.Provider value={{ 
        getNotifications: notificationService.getNotifications, 
        addNotification, 
        removeNotification, 
        removeAllNotifications 
    }}>
        { children }
    </NotificationContext.Provider>)
}