//import { IModelBase } from '../../../_models/types'
import { GenericResponse } from '../../utils/response'
import { API_URL as API_BASE } from '../AppConfig'

export type IOrder = { [ field: string ]: 'ASC' | 'DESC' }

export type IWhereOperatorValue = string | Date
export type IWhereOperator = { $gt: IWhereOperatorValue } | string | { $lt: IWhereOperatorValue } | { $gte: IWhereOperatorValue } | { $lte: IWhereOperatorValue } | null
export type IWhereLogicalOperator = { $and: IWhereCondition[] } | { $or: IWhereCondition[] }
export type IWhereCondition = { [ field: string ]: IWhereOperator } | IWhereLogicalOperator

export interface GetAllOptions {
    key?: string | number,
    fields?: string[], 
    where?: IWhereCondition, 
    order?: IOrder, 
    filter?: string
}
export interface IGetService<T> {
    getAll( options: GetAllOptions ): Promise<T[]>;
    getOne( id: number ): Promise<T>;
}


export class GenericService<T> implements IGetService<T> {

    public API_URL: string

    constructor( API_URL: string ) {
        this.API_URL = API_BASE + API_URL
    }

    public async getAll( { key, fields, where, order, filter }: GetAllOptions ): Promise<T[]> {
        const params = new URLSearchParams( {
            ...(typeof fields !== 'undefined' ? { fields: encodeURIComponent( JSON.stringify( fields ) ) } : {}),
            ...(typeof where !== 'undefined' ? { where: encodeURIComponent( JSON.stringify( where ) ) }: {}),
            ...(typeof order !== 'undefined' ? { order: encodeURIComponent( JSON.stringify( order || {} ) ) }: {}),
            ...(typeof filter !== 'undefined' ? { filter } : {} )
        } )
        const result = await fetch( this.API_URL + ( typeof key == 'undefined' ? '' : key.toString() ) + '?' + params.toString(), { method: 'GET', headers: { 'Accept': 'application/json' } } )
        const res = (await result.json()) as GenericResponse<T>
        if( !res.success ) throw new Error( 'Unknown server error.' )
        
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return res.data.map( ( item ) => this.parse( item ) )
    }

    public async getOne( id: number ): Promise<T> {
        const result = await fetch( this.API_URL + String( id ), { method: 'GET', headers: { 'Accept': 'application/json' } } )
        const res = (await result.json()) as GenericResponse<T>
        if( !res.success ) throw new Error( 'Unknown server error.' )
        return this.parse( res.data[ 0 ] )
    }

    protected parse(item: T): T {
        return item
    }
}

