import React, { MouseEventHandler } from 'react'
import { Row, Col, Button } from 'react-bootstrap'

import { IAttn } from '../../../../../../cz.samaya.api/src/_api-models/index'
import { FaMinusCircle } from 'react-icons/fa'

type AttachmentItemProps<T extends IAttn> = {
    item: T
    onDelete?: MouseEventHandler<HTMLButtonElement>
}

export const AttachmentItem = <T extends IAttn>( { item, onDelete }: AttachmentItemProps<T> ) => {
    return <tr>
        { onDelete && <td><Button onClick={ onDelete }><FaMinusCircle /></Button></td> }
        <td>{ item.type }</td>
        <td>{ item.source }</td>
        <td>{ item.filename }</td>
        <td>{ item.name }</td>
        <td>{ item.thumb }</td>
    </tr>
}