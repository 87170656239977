import React, { forwardRef } from 'react'
import { useIntl } from 'react-intl'

import { useCountries } from '../../hooks/useCountries'
import { Combo, ComboProps } from '../Combo'

export type ContriesProps = Omit<ComboProps, 'children'> & { apiUrl: string }

export const Countries = forwardRef<HTMLSelectElement, ContriesProps>( ({ apiUrl, ...props }: ContriesProps, ref ) => {

    const intl = useIntl()
    
    const [ countries ] = useCountries( apiUrl )

    return <Combo ref={ ref } { ...props } >
        { countries.map( c => 
            <option key={ c.code } value={ c.code }>{ c.name }</option>
        ) }
    </Combo>
} )