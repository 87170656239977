import React, { createContext, useState } from 'react'

import { ComponentWithChildrenProps } from '../types'
import { HttpRequestContextCache } from '../utils/HttpRequestCache'

export type HttpRequestConfig = {
    getAuthHeader?: () => HeadersInit | undefined
    cacheExpiry: number
    noAbort?: boolean
    timeout?: number
}

export type HttpRequestContextType = {
    cache: HttpRequestContextCache
    options: HttpRequestConfig
    setOptions: ( newConfig: HttpRequestConfig ) => void
}

const initHttpRequestContent: HttpRequestContextType = {
    cache: new Map(),
    options: {
        cacheExpiry: 60 * 60 * 1000 // ms = 60 minutes
    },
    setOptions: () => {}
}

export const HttpRequestContext = createContext<HttpRequestContextType>( initHttpRequestContent )

export type HttpRequestProviderProps = {
    cache?: HttpRequestContextCache 
    options?: HttpRequestConfig
} & ComponentWithChildrenProps;

export const HttpRequestProvider = ( { cache, options, children }: HttpRequestProviderProps ) => {

    const [ options_, setOptions ] = useState<HttpRequestConfig>( { ...options, ...initHttpRequestContent.options } )

    return <HttpRequestContext.Provider value={{ 
        cache: cache || initHttpRequestContent.cache,
        options: options_,
        setOptions
    }}>
        { children }
    </HttpRequestContext.Provider>
}