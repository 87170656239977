/* eslint-disable @typescript-eslint/ban-types */
import { DependencyList, useEffect, useRef } from 'react'

export const useTimeout = ( callback: Function, delay: number | null | undefined, deps: DependencyList ) => {

    const timeoutRef = useRef<number | null>( null )

    const savedCallback = useRef<Function>( callback )

    useEffect( () => {
        savedCallback.current = callback
    }, [ callback ] )

    useEffect( () => {
        const tick = () => void savedCallback.current()
        if( typeof delay === 'number' ) {
            timeoutRef.current = window.setTimeout( tick, delay )
            return () => { if( timeoutRef.current ) window.clearTimeout( timeoutRef.current ) }
        }
        return
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ delay, ...deps ] )
    
    return timeoutRef
}

