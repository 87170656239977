import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import img1 from '../../../assets/img/jumbo05.jpg?format=webp'
import img2 from '../../../assets/img/jumbo06.jpg?format=webp'

export const Home = () => {

    const [ adVisible, setAdVisible ] = useState( false )

    useEffect( () => {
        if( ( localStorage.getItem( 'adShown' ) || '' ) !== '2022/2023' ) {
            localStorage.setItem( 'adShown', '2022/2023' )
            setAdVisible( true )
        }
    }, [] )

    return(<>
        <div className="home">
            {/*<div className='text-center'>
                <a href="#" onClick={ () => { localStorage.setItem( 'adShown', '2022/2023' ); setAdVisible( true ) } }>Přehrát znovu reklamu</a>
            </div>*/}
            <div className="jumbotron jumbotron-fluid" style={{ backgroundImage: `url(${ img1.src })` }}>
                <div className="container text-white">
                    <h1>Rozvrh</h1>
                    <p>Celotýdenní program dle data</p>
                    <p className="lead">
                        <Link className="btn btn-primary btn-md" to="/rozvrh" role="button">Více se dozvíte zde</Link>
                    </p>
                </div>
            </div>
            <div className="jumbotron jumbotron-fluid" style={{ backgroundImage: `url(${ img2.src })` }}>
                <div className="container text-white">
                    <h1>Kurzy</h1>
                    <p>Vyberte si ten svůj</p>
                    <p className="lead">
                        <Link className="btn btn-primary btn-md" to="/kurzy/pravidelne" role="button">Více se dozvíte zde</Link>
                    </p>
                </div>
            </div>
        </div>
        <Modal
            show={ adVisible } 
            onHide={ () => setAdVisible( false ) } 
            className='video-modal'
        >
            <Modal.Header closeButton>
                <Modal.Title>Přihlášky do kurzů otevřeny</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/_JTgWOd4Q1Q" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Modal.Body>
        </Modal>
    </>)
}