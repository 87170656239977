import React, { useState, useEffect, ReactNode } from 'react'
import { useIntl } from 'react-intl'
import { Navigate, useLocation } from 'react-router-dom'

import { useAuth } from '../../../Auth'
import { useNotifications } from '../../../Notifications'

type ComponentState = 'LOADING' | 'OK_AUTHORIZED' | 'OK_UNAUTHORIZED'

type AuthRouteProps = {
    roles?:  string[] | string
    children: ReactNode
}

export const AuthRoute = ( { roles, children }: AuthRouteProps ) => {

    const intl = useIntl()

    const { pathname } = useLocation()

    const auth = useAuth()

    const { addNotification } = useNotifications()

    const [ state, setState ] = useState<ComponentState>( 'LOADING' )

    let isMounted = true
    
    useEffect( () => {
        const isA = auth?.hasRole( roles ) ?? false
        if( isMounted ) {
            if( !isA ) addNotification({ variant: 'danger', category: 'auth', content: intl.formatMessage({ description: 'auth.authRoute.notAuthorized', defaultMessage: 'Login required!' }), timeout: 3000 })
            setState( isA ? 'OK_AUTHORIZED' : 'OK_UNAUTHORIZED' )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => { isMounted = false }
    }, [ auth ] )

    return(
        <>
            { state === 'LOADING' &&
                <div>Loading...</div>
            }
            { state === 'OK_AUTHORIZED' && children }
            { state === 'OK_UNAUTHORIZED' &&
                <Navigate to='/log-in' replace={ true } state={{ from: pathname }} />
            }
        </>
    )
}