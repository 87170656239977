import { AuthData, AuthOperResult, AuthService, User } from '../../..'

import { checkAuthenticated, checkReAuthenticationToken, getReAuthenticationInterval, getUser, hasRole, getAuthHeader } from '../utils'

export const AuthServiceMock = <T extends User>({ users }: { users: Map<string, T & { pwd: string }> }): AuthService<T> => {
    
    const usersMock = users

    const findUser = ( username: string ): T & { pwd: string } | null => {
        let _user: T & { pwd: string } | null = null
        usersMock.forEach( user => { 
            if( username.toLowerCase() === user?.email ) _user = user
        } )
        return _user
    }
    
    // eslint-disable-next-line @typescript-eslint/require-await
    const login = async ( username: string, pwd: string ): Promise<AuthOperResult<T>> => {
        const user = findUser( username )
        
        if( user && user.pwd === pwd ) {
            const { pwd, ...userWoPwd } = user
            return {
                success: true,
                status: 200,
                message: 'Login succesful',
                data: {
                    token: 'token',
                    user: userWoPwd as unknown as T,
                    tokenExpiry: 0
                }
            }
        }
        return { success: false, status: 401, message: 'Unknown user.' }
    }
    
    // eslint-disable-next-line @typescript-eslint/require-await
    const reAuthenticate = async ( authData?: AuthData<T> ): Promise<AuthOperResult<T>> => {
        // TODO: implement!
        return ({ success: false, status: 501, message: 'This is dummy service, actual Authentication service not initialized.' })
    }
    
    // eslint-disable-next-line @typescript-eslint/require-await
    const pwdReset = async ( username: string ): Promise<AuthOperResult<T>> => {
        const user = findUser( username )
        
        if( user ) {
            return { success: true, status: 200, message: 'Password reset.' }
        }
        return { success: false, status: 400, message: 'Reset password failed' }
    }
    
    return {
        checkAuthenticated, checkReAuthenticationToken, getReAuthenticationInterval, getUser, hasRole, login, reAuthenticate, pwdReset, getAuthHeader
    }
}
