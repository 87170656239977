import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

export const useScrollToAnchor = () => {

    const { pathname } = useLocation()
    const { hash } = useLocation()

    const scrolledRef = useRef( false )
    const hashRef = useRef( hash )

    useEffect( () => {
        if( !hash ) {
            const canControlScrollRestoration = 'scrollRestoration' in window.history
            if( canControlScrollRestoration ) window.history.scrollRestoration = 'manual'
            window.scrollTo( 0, 0 )
        }
    }, [ pathname ] )
  
    useEffect( () => {
        // prevent default browser behaviour
        const canControlScrollRestoration = 'scrollRestoration' in window.history
        if( canControlScrollRestoration ) window.history.scrollRestoration = 'manual'
        // if hash go to hash
        if( hash ) {
            // We want to reset if the hash has changed
            if( hashRef.current !== hash ) {
                hashRef.current = hash
                scrolledRef.current = false
            }
    
            // only attempt to scroll if we haven't yet (this could have just reset above if hash changed)
            if( !scrolledRef.current ) {
                const id = hash.replace( '#', '' )
                const element = document.getElementById( id )
                if( element ) {
                    element.scrollIntoView({ behavior: 'smooth' })
                    scrolledRef.current = true
                }
            }
        }
    }, [ hash, pathname ] )
}