import { fetchExt, FetchExtResult } from '../../../Common'

import { AuthService, AuthOperResult, AuthData, User } from '../..'

import { checkAuthenticated, checkReAuthenticationToken, getReAuthenticationInterval, getUser, hasRole, getAuthHeader } from './utils'

// global value set by factory function
let _API_URL = ''

const promiseResolve = <T extends User>( promise: Promise<FetchExtResult<AuthOperResult<T>>>, resolve: ( value: AuthOperResult<T> | PromiseLike<AuthOperResult<T>> ) => void, reject: ( reason: AuthOperResult<T> ) => void ) => {
    promise
        .then( result => resolve({ ...result.bodyData, status: result.status }) )
        .catch( ( error: unknown ) => {
            console.error( error )
            resolve({ 
                success: false, 
                status: 500,
            })
        } )
}

type LoginBodyParam = { username: string, pwd: string }
const login = async <T extends User>( username: string, pwd: string ): Promise<AuthOperResult<T>> => new Promise( (resolve, reject ) => {

    const [ promise ] = fetchExt<AuthOperResult<T>, LoginBodyParam>( _API_URL + '/auth/auth', {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        data: { username, pwd }
    } )

    promiseResolve( promise, resolve, reject )
})


const reAuthenticate = async <T extends User>( authData?: AuthData<T> ): Promise<AuthOperResult<T>> => new Promise( ( resolve, reject ) => {
    if( !authData || !authData.reToken ) reject({ 
        success: false, 
        status: 403 /* Forbidden */
    })

    else {
        const [ promise ] = fetchExt<AuthOperResult<T>, never>( _API_URL + '/auth/re-auth', {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json', 
                'Accept': 'application/json',
                ...getAuthHeader( authData, true )
            }
        } )
    
        promiseResolve( promise, resolve, reject )
    }
} )

type PwdResetBodyParam = { username: string }
const pwdReset = async <T extends User>( username: string ): Promise<AuthOperResult<T>> => new Promise( ( resolve, reject ) => {

    const [ promise ] = fetchExt<AuthOperResult<T>, PwdResetBodyParam>( _API_URL + '/auth/resetPwd', {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        data: { username }
    } )

    promiseResolve( promise, resolve, reject )
} )

type PwdChangeBodyParam = LoginBodyParam
const pwdChange = async <T extends User>( authData: AuthData<T> | undefined, pwd: string ): Promise<AuthOperResult<T>> => new Promise( ( resolve, reject ) => {

    if( !authData || !authData.user ) reject({ 
        success: false, 
        status: 401 /* Un-authorized */
    })

    else {
        const username = authData.user.email
    
        const [ promise ] = fetchExt<AuthOperResult<T>, PwdChangeBodyParam>( _API_URL + '/auth/changePwd', {
            method: 'POST',
            headers: { 
                ...getAuthHeader( authData ), 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            data: { username, pwd }
        } )
    
        promiseResolve( promise, resolve, reject )
    }
} )

export const AuthServiceAPI = <T extends User>( API_URL: string ): AuthService<T> => {
    _API_URL = API_URL
    return {
        checkAuthenticated, 
        checkReAuthenticationToken, 
        getReAuthenticationInterval, 
        getUser, 
        hasRole, 
        login, 
        reAuthenticate, 
        pwdReset, 
        pwdChange,
        getAuthHeader
    }
}
    

