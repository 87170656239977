import React, { forwardRef } from 'react'
import { Form as BSForm } from 'react-bootstrap'

import { useOnChange } from '../../hooks/useOnChange'
import { IFormDataVals } from '../../types'

type CheckBoxProps = {
    controlId: string
    type?: 'checkbox' | 'radio' | 'switch'
    name?: string
    className?: string
    required?: boolean
    disabled?: boolean
    label?: string
    invalidMsg?: string
    children?: React.ReactNode
    onChange?: ( ev: React.ChangeEvent<HTMLInputElement  | HTMLSelectElement | HTMLTextAreaElement> | IFormDataVals ) => void
}

export const CheckBox = forwardRef<HTMLInputElement, CheckBoxProps>( ({ controlId, type, name, className, label, invalidMsg, disabled, children, onChange: onChangeOrig, ...props }: CheckBoxProps, ref ) => {

    const { form, onChange } = useOnChange( controlId, 'checked', onChangeOrig )

    return(<BSForm.Group controlId={ controlId } className={ [ 'mb-3', className ].join( ' ' ) }>
        <BSForm.Check 
            ref={ ref }
            { ...props }
            type={ type || 'checkbox' } 
            name={ name }
            checked={ !form ? false 
                : type === 'radio' && name 
                    ? form.data[ name ] === controlId
                    : form.data[ controlId ] as boolean 
            }
            onChange={ onChange }
            label={ children || label } 
            disabled={ disabled || form?.processing }
        />
        { invalidMsg && <BSForm.Control.Feedback type='invalid'>{ invalidMsg }</BSForm.Control.Feedback> }
    </BSForm.Group>)
} )
