import React, { Suspense } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
//import { QueryClient, QueryClientProvider } from 'react-query'
import { Container, Row, Col } from 'react-bootstrap'

import { AuthContextProvider, AuthRoutes, AuthServiceAPI } from '../../../Auth'
import { NotificationStack, NotificationOnPageLoad } from '../../../Notifications'
import { ToTopBtn, ToTopOnRoute } from '../../../Common'

import { API_URL, BASENAME } from '../../AppConfig'
import { ROUTES } from '../../AppRoutes'
import { User } from '../../AppTypes'

import { AppContext } from '../AppContext'
import { AppSideBar } from '../AppSideBar'
import { AppLoading } from '../AppLoading'
import { AppPageHeading } from '../AppPageHeading'
import { Home } from '../Home'

import '../../../assets/scss/styles.scss'

const authService = AuthServiceAPI<User>( API_URL )

export const AppContent = () => (
    <Container fluid>
        <NotificationOnPageLoad />
        <ToTopOnRoute />
        <ToTopBtn />
        <AuthContextProvider 
            authService={ authService } 
            loginHome='/'
            pageHeading={ AppPageHeading }
        >
            <Row>
                <Col md={ 4 } lg={ 3 } className='text-center'>
                    <div className='sticky-top'><AppSideBar /></div>
                </Col>                        
                <Col>
                    <Suspense fallback={ <AppLoading /> }>
                        <AuthRoutes routes={ ROUTES } defaultRoute={{ element: <Home />, isAuthenticated: false }} />
                    </Suspense>
                    <div className="pb-5"></div>
                </Col>
            </Row>
        </AuthContextProvider>
        <NotificationStack />
    </Container>            
)
export const App = () => (
    <AppContext>
        <Router basename={ BASENAME }>
            <AppContent />
        </Router>
    </AppContext>
)