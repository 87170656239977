import React, { MouseEventHandler, useEffect, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { Container, Button } from 'react-bootstrap'
import { FaPlusCircle } from 'react-icons/fa'


import { useOnChange } from '../../hooks/useOnChange'

import { AttachmentItem } from './AttachmentItem'

import { IAttn } from '../../../../../cz.samaya.api/src/_api-models/index'


type AttachmentsProps = {
    controlId: string
    disabled?: boolean
    allowAdd?: boolean
    allowDelete?: boolean
}
export const Attachments = <T extends IAttn>( { controlId, disabled, allowAdd, allowDelete, ...props }: AttachmentsProps ) => {

    const intl = useIntl()

    const [ attns, setAttns ] = useState<T[]>( [] )

    const { form, onChange } = useOnChange( controlId, 'literal' )

    const addAttachment = () => {
        console.log( 'Add attachment' )
    }

    const deleteAttachment = ( index: number ) => {
        setAttns( attns.splice( index, 1 ) )
        onChange( attns )
    }

    useEffect( () => {
        if( form ) {
            const attns = form.data[ controlId ] as T[]
            setAttns( attns )    
        }
    }, [ form ] )

    return <Container>
        { allowAdd && !disabled && <Button variant='primary' type='submit' onClick={ addAttachment }><FaPlusCircle /><FormattedMessage description="Forms.Attachment.AddAttnBtn" defaultMessage="Přidat přílohu" /></Button> }
        { attns && attns.map( ( attn, index ) => <AttachmentItem key={ index } item={ attn } onDelete={ allowDelete && !disabled ? () => deleteAttachment( index ) : undefined } /> ) }
    </Container>
}