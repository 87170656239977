import React from 'react'
import { createRoot, hydrateRoot } from 'react-dom/client'

import { App } from './App/components/App'

const container = document.getElementById( 'app-root' )

import './assets/scss/styles.scss'

if( container ) {
    if( container.hasChildNodes() ) hydrateRoot( container, <App /> )
    else {
        const reactRoot = createRoot( container )
        reactRoot.render( <App /> )
    }
}