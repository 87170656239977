import { useCallback } from 'react'

import { useForm } from './useForm'
import { IFormDataVals } from '../types'

export const useOnChange = ( controlId: string, type: 'value' | 'checked' | 'literal', onChange_?: ( ev: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | IFormDataVals ) => void  ) => {
    const form = useForm()

    const onChange = useCallback( ( ev: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | IFormDataVals ) => {
        let val: IFormDataVals = ''
        if( type === 'literal' ) val = ev as IFormDataVals
        else if( type === 'checked' && typeof ev == 'object' ) val = ((ev as React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>).currentTarget as HTMLInputElement).checked
        else if( type === 'value' && typeof ev === 'object' ) val = ((ev as React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>).currentTarget).value
        form?.setData({ ...form.data, [ controlId ]: val }) 
        if( onChange_ ) onChange_( ev )
    }, [ form, controlId ] )

    return { form, onChange }
}