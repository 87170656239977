type FetchExtInit<U> = Omit<RequestInit, 'body'> & { data?: U, timeout?: number, contentType?: string, responseType?: string }
export type FetchExtResult<T> = Pick<Response, 'status' | 'statusText' | 'ok' | 'redirected' | 'type' | 'headers' | 'url'> & { bodyData: T }
export type FetchAbort = ( reason?: unknown ) => void
export type FetchExtReturn<T> = [ Promise<FetchExtResult<T>>, FetchAbort ]

type FetchExtOverloads = {
    <T>( url: RequestInfo, init?: FetchExtInit<unknown> ): FetchExtReturn<T>
    <T,U>( url: RequestInfo, init?: FetchExtInit<U> ): FetchExtReturn<T>
}

export const fetchExt: FetchExtOverloads = <T,U>( url: RequestInfo, init?: FetchExtInit<U> ): FetchExtReturn<T> => {

    // provide 
    const body = init?.data ? JSON.stringify( init.data ) : undefined
    const contentType = init?.contentType ? init.contentType : 'application/json'

    const abortController = new AbortController()
    const abort = ( reason: unknown ) => abortController.abort( reason )

    if( init?.timeout && init?.timeout > 0 ) {
        setTimeout( 
            () => abort( 'FETCH-TIMEOUT' ),
            init?.timeout
        )
    }

    const fetchExtResult = new Promise<FetchExtResult<T>>( ( resolve, reject ) => {
        fetch( url, { ...init, body, signal: abortController.signal } )
            .then( res => {
                const { status, statusText, url, redirected, headers, ok, type } = res
                if( contentType === 'application/json' )
                    (res.json() as Promise<T>)
                        .then( bodyData => {
                            resolve( { status, statusText, url, redirected, headers, ok, type, bodyData } )
                        } )
                        .catch( ( ...args ) => reject( args ) )
                else resolve( { status, statusText, url, redirected, headers, ok, type, bodyData: res.text() as T } )
            } )
            .catch( ( ...args ) => {
                reject( args )
            } )
    } )
    
    return [ fetchExtResult, abort ]
}