import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Row } from 'react-bootstrap'

import { AppPageHeading } from '../../../App/components/AppPageHeading'
import { AppStatus } from '../../../App/components/AppStatus'
import { useRows } from '../../../App/hooks/useRows'

import { NewsService  } from '../../services/news'
import { News as NewsModel } from '../../../../../cz.samaya.api/src/_api-models/index'
import { New } from './New'

import news from '../../../assets/img/news.jpg?format=webp'
import blog from '../../../assets/img/blog.jpg?format=webp'

export const News = () => {

    const intl = useIntl()

    const { type } = useParams()
    let title: string, titlePhoto: string, filter: string
    if( type === 'news' ) {
        title = intl.formatMessage({ description: 'Aktuality nadpis', defaultMessage: 'Aktuality' }) 
        titlePhoto = news.src
        filter = 'currentNews'
    } else {
        title = intl.formatMessage({ description: 'Blog nadpis', defaultMessage: 'Blog' }) 
        titlePhoto = blog.src
        filter = 'currentArticle'
    }

    const service = useMemo( () => new NewsService(), [] )
    const order = useMemo( () => ({ modified: 'DESC' as const }), [] )
    const { loading, error, rows } = useRows<NewsModel>( service, { filter, order } )

    return(<>
        <AppPageHeading imgUrl={ titlePhoto } title={ title } />
        <AppStatus error={ error } loading={ loading } />
        <Row>
            { rows.map( r => <New key={ r.highlightId } row={ r } />) }
        </Row>
    </>)
}