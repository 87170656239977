import React, { forwardRef } from 'react'

import { Expandable } from '../../../../App/components/Expandable'

import { Block } from '../../Block'

import { Lector as LectorModel } from '../../../../../../cz.samaya.api/src/_api-models/index'

type LectorProps = {
    row: LectorModel
}

export const Lector = forwardRef<HTMLDivElement, LectorProps>( ( { row }: LectorProps, ref ) => {

    return(
        <Block ref={ ref } title={ row.name || '' } imgUrl={ row.images.length > 0 ? row.images[ 0 ].src : '' } editLink={ '/admin/lector/' + row.id }>
            <div className="text-box text-left"> 
                <blockquote className="blockquote">
                    { row.email && <div>Email: <a href={ `mailto:${ row.email }` }>{ row.email }</a></div> }
                    { row.facebook && <div>Facebook: <a href={ row.facebook }>{ row.facebook }</a></div> }
                    { row.phone && <div>Mobil: { row.phone }</div> }
                </blockquote >
                <Expandable content={ row.description || '' } />
            </div>
        </Block>)
} )
