import React from 'react'
import { Routes, Route } from 'react-router-dom'

import { AuthRoute } from '../AuthRoute'

import { Route as RouteType } from '../../../Common'

type AuthRoutesProps = {
    routes: RouteType[]
    defaultRoute?: Pick<RouteType, 'element' | 'isAuthenticated' | 'roles'>
}

export const AuthRoutes = ( { routes, defaultRoute }: AuthRoutesProps ) => {
    return(<Routes>
        { routes.map( item => {
            if( typeof item.route === 'string' )
                return <Route key={ item.route } path={ item.route } element={
                    !item.isAuthenticated && item.element ? item.element :
                        <AuthRoute key={ item.route } roles={ item.roles ?? 'any' }>{ item.element }</AuthRoute>
                }></Route> 
            else return item.route.map( route => <Route key={ route } path={ route } element={
                !item.isAuthenticated && item.element ? item.element :
                    <AuthRoute key={ route } roles={ item.roles ?? 'any' }>{ item.element }</AuthRoute>
                }></Route>
            )
        }) }
        { defaultRoute && <Route key='default' path='*' element={ 
            !defaultRoute.isAuthenticated && defaultRoute.element ? defaultRoute.element :
                <AuthRoute key='default' roles={ defaultRoute.roles ?? 'any' }>{ defaultRoute.element }</AuthRoute> } 
        /> }
    </Routes>)
}