import React, { createContext, useState } from 'react'
import { IntlProvider } from 'react-intl'

import { II18NContext, Lang } from '../types'

import { ComponentWithChildrenProps, useQueryString } from '../../Common'

export const LANG_EN_US: Lang = {
    isoCode: 'en-US',
    label: 'EN',
    title: 'English',
    messages: {}
}

type I18NContextProviderProps = {
    langDefault?: Lang
    langList: Lang[]
    activeLang?: Lang
} & ComponentWithChildrenProps

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const I18NContext = createContext<II18NContext>({ lang: LANG_EN_US, onLangChange: () => {} })

export const I18NContextProvider = ( { langList, langDefault, activeLang, children }: I18NContextProviderProps ) => {

    if( !langDefault ) langDefault = langList[ 0 ]

    const getLang = ( isoCode?: string ): Lang => {
        return ( isoCode ? langList.find( lang => lang.isoCode.substring( 0, 2 ) === isoCode.substring( 0, 2 ) ) || langDefault : langDefault ) || langList[ 0 ]
    } 
    
    const urlLocale = useQueryString( 'locale' )
    // in case of server render navigator does not exists
    const browserLocale = typeof navigator !== 'undefined' ? navigator.language : ''
    const [ lang, setLang ] = useState( getLang( activeLang?.isoCode || urlLocale || browserLocale || langDefault.isoCode ) )

    const onLangChange = ( locale: string ) => {
        setLang( getLang( locale ) )
    }

    return(
        <I18NContext.Provider value={{ lang, onLangChange }}>
            <IntlProvider locale ={ lang.isoCode } messages={ lang.messages }>
                { children }
            </IntlProvider>
        </I18NContext.Provider>
    )
}


