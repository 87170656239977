import { GenericService } from '../../App/services/generic'

import { Page } from '../../../../cz.samaya.api/src/_api-models/index'

export class PagesService extends GenericService<Page> {
    constructor() { super( '/pages/' ) }

    public parse( item: Page ) {

        if( item.eff_dt_to ) item.eff_dt_to = new Date( item.eff_dt_to as unknown as string )
        item.modified = new Date( item.modified as unknown as string )

        /*if( item.attachments ) {
            item.images = []
            item.attns = []
            for( const attn of item.attachments ) {
                switch( attn.type ) {
                case 'image':
                    //item.images.push( attn )
                    break
                default:
                    item.attns.push( attn.filename )
                }
            }
        }*/
        return item
    }
}

