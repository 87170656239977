import { GenericService } from '../../App/services/generic'

import { News } from '../../../../cz.samaya.api/src/_api-models/index'

export class NewsService extends GenericService<News> {
    constructor() { super( '/news/' ) }

    public parse( item: News ) {

        if( item.eff_dt_to ) item.eff_dt_to = new Date( item.eff_dt_to as unknown as string )
        item.modified = new Date( item.modified as unknown as string )

        /*if( item.attachments ) {
            item.images = []
            item.attns = []
            // tslint:disable-next-line:forin
            for (const attn of item.attachments) {
                switch (attn.type) {
                case 'image':
                    //item.images.push(attn)
                    break
                default:
                    //item.attns.push(attn)
                }
            }
        }*/
        return item
    }
}

