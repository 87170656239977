import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { Container, Nav, NavDropdown, Navbar } from 'react-bootstrap'
import { WEB_LOGO } from '../../AppConfig'
import { useAuth, User } from '../../../Auth'

export const AppSideBar = () => {

    const intl = useIntl()

    const auth = useAuth()
    const [ user, setUser ] = useState<User | undefined>()

    const [ menuIsExpanded, setMenuIsExpanded ] = useState( false )

    useEffect( () => {
        setUser( auth.getUser() )
    }, [ auth ] )

    return(<>
        <Link className="nav-link" to="/" onClick={ () => setMenuIsExpanded( false ) } title={ intl.formatMessage({ description: 'zpět na home page title', defaultMessage: 'zpět na hlavní stránku' }) } >
            <img src={ WEB_LOGO[ 0 ].logo } alt='Samaya logo' className='w-100' />
        </Link>
        <hr className='w-50 d-inline-block' />
        <Navbar expand="md" variant="light" expanded={ menuIsExpanded }>
            <Container style={{ justifyContent: 'center' }}>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={ () => setMenuIsExpanded( !menuIsExpanded ) } />
                <Navbar.Collapse id="responsive-navbar-nav" style={{ justifyContent: 'center' }}>
                    <Nav className="flex-column text-center" onClick={ () => setMenuIsExpanded( false ) }>
                        { user && user.roles.includes( 'admin' ) && <NavDropdown title="Admin">
                            <NavDropdown.Item as={ Link } to="/admin/pages"><FormattedMessage defaultMessage='Stránky & bloky' /></NavDropdown.Item>
                        </NavDropdown> }
                        <Nav.Link as={ Link } to='/p/samaya'><FormattedMessage defaultMessage='Samaya' /></Nav.Link>
                        <Nav.Link as={ Link } to="/rozvrh"><FormattedMessage defaultMessage='Rozvrh' /></Nav.Link>
                        <Nav.Link as={ Link } to="/kurzy/pravidelne"><FormattedMessage defaultMessage='Kurzy' /></Nav.Link>
                        <Nav.Link as={ Link } to="/kurzy/workshopy"><FormattedMessage defaultMessage='Workshopy' /></Nav.Link>
                        <Nav.Link as={ Link } to="/lektori"><FormattedMessage defaultMessage='Lektoři' /></Nav.Link>
                        <Nav.Link as={ Link } to="/aktuality/news"><FormattedMessage defaultMessage='Aktuality' /></Nav.Link>
                        <Nav.Link as={ Link } to="/p/soukrome"><FormattedMessage defaultMessage='Soukromé lekce' /></Nav.Link>
                        <Nav.Link as={ Link } to="/p/kodex"><FormattedMessage defaultMessage='Kodex studia' /></Nav.Link>
                        <Nav.Link as={ Link } to="/p/pravidlacerpaninahrad"><FormattedMessage defaultMessage='Pravidla čerpání náhrad' /></Nav.Link>
                        <Nav.Link as={ Link } to="/p/kontakt"><FormattedMessage defaultMessage='Kontakt' /></Nav.Link>
                        <Nav.Link as={ Link } to="/aktuality/blog"><FormattedMessage defaultMessage='Blog' /></Nav.Link>
                        <Nav.Link as={ Link } to="/galerie"><FormattedMessage defaultMessage='Galerie' /></Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    </>)
}