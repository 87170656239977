import React, { forwardRef } from 'react'
import { useIntl } from 'react-intl'

import { Expandable } from '../../../../App/components/Expandable'

import { News as NewsModel } from '../../../../../../cz.samaya.api/src/_api-models/index'
import { Block } from '../../Block'

type NewProps = {
    row: NewsModel
}

export const New = forwardRef<HTMLDivElement, NewProps>( ( { row }: NewProps, ref ) => {

    const intl = useIntl()

    return(
        <Block ref={ ref } title={ row.heading || 'NA' } subtitle={ intl.formatDate( row.modified || '' ) } imgUrl={ row.images.length > 0 ? `${ row.images[ 0 ].filename }` : '' } editLink={ '/admin/news/' + row.highlightId }>
            { row.description && <>
                <div className="text-box text-left">
                    <Expandable content={ row.description || '' } />
                </div>
                { row.attns.length > 0 && <blockquote className="blockquote">
                    <div>Přílohy:</div>
                    <ul>
                        { row.attns.map( ( a, i ) => <li key={ i }><a href={ `${ a.filename }` } target="_self" download>{ a.name }</a></li> ) }
                    </ul>
                </blockquote> }
            </> }
            { ! row.description && row.attns.length > 0 && <>
                <div className="text-box text-left">
                    <img className="w-100" src={ `${ row.attns[ 0 ].filename }` } alt={ row.attns[ 0 ].name } />
                </div>
                { row.attns.length > 1 && <blockquote className="blockquote">
                    <div>Přílohy:</div>
                    <ul>
                        { row.attns.map( ( a, i ) => i > 0 && <li key={ i }><a href={ `${ a.filename }` } target="_self" download>{ a.name }</a></li> ) }
                    </ul>
                </blockquote> }
            </> }
        </Block>
    )
} )
