import React, { useCallback, useEffect, useReducer, useRef } from 'react'

type ReducerCallback<S> = ( state: S ) => void
type ReducerDispatchWithCallback<S,A> = ( value: A, callback?: ReducerCallback<S> ) => void

export const useReducerWithCallback = <S,A>( reducer: React.Reducer<S,A>, initilizerArg: React.ReducerState<React.Reducer<S,A>> ): [ S, ReducerDispatchWithCallback<S,A> ] => {

    const [ state, dispatch_ ] = useReducer( reducer, initilizerArg )

    const callback_ = useRef<ReducerCallback<S> | null>( null )
    const dispatch = useCallback( ( value: A, callback?: ReducerCallback<S> ) => {
        if( callback ) callback_.current = callback
        dispatch_( value )
    }, [] )

    useEffect( () => {
        callback_.current?.( state )
        callback_.current = null
    }, [ state ] )

    return [ state, dispatch ]
}