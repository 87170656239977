import classNames from 'classnames'
import React, { ReactNode } from 'react'

type PageHeadingProps = {
    imgUrl?: string
    title?: string
    children?: ReactNode
}

export const AppPageHeading = ( { imgUrl, title, children }: PageHeadingProps ) => {
    return(<>
        { ( imgUrl || title ) && <div className="jumbotron jumbotron-fluid" style={{ backgroundImage: `url(${ imgUrl || '' })` }} >
            <div className={ classNames([ 'container', typeof imgUrl != 'undefined' ? 'text-white' : 'text-dark' ]) }>
                <h1>{ title }</h1>
            </div>
        </div> }
        { typeof title == 'undefined' ? children : '' }
    </>)
}
