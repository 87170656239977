import React, { useEffect, useMemo, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Row } from 'react-bootstrap'

import { AppPageHeading } from '../../../App/components/AppPageHeading'
import { AppStatus } from '../../../App/components/AppStatus'
import { useRows } from '../../../App/hooks/useRows'

import { Lector as LectorModel } from '../../../../../cz.samaya.api/src/_api-models/index'
import { LectorService } from '../../services/lector'
import { Lector } from './Lector'

import lektori from '../../../assets/img/lektori.jpg?format=webp'

export const Lectors = () => {
    
    const intl = useIntl()

    const service = useMemo( () => new LectorService(), [] )
    const order = useMemo( () => ({ vip: 'ASC' as const }), [] )
    const { loading, error, rows } = useRows<LectorModel>( service, { filter: 'current', order } )

    const { hash } = useLocation()
    const lectorId = hash ? hash.substring( 2 ) : ''  // remove letter #L and make it number
    const lectorRef = useRef<HTMLDivElement | null>( null )
    useEffect( () => {
        if( lectorRef.current ) lectorRef.current.scrollIntoView()
    }, [ rows ] )

    return(<>
        <AppPageHeading imgUrl={ lektori.src } title={ intl.formatMessage({ description: 'Lektoři nadpis', defaultMessage: 'Lektoři' }) } />
        <AppStatus error={ error } loading={ loading } />
        <Row>
            { rows.map( r => <Lector ref={ el => { if( r.id == lectorId ) lectorRef.current = el } } key={ r.id } row={ r } /> ) }
        </Row>
    </>)
}