import React, { useEffect, useMemo, useRef } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Col, Row } from 'react-bootstrap'

import { AppPageHeading } from '../../../App/components/AppPageHeading'
import { AppStatus } from '../../../App/components/AppStatus'
import { useRows } from '../../../App/hooks/useRows'

import { CoursesService  } from '../../services/courses'
import { type Event as EventModel } from '../../../../../cz.samaya.api/src/_api-models/index'
import { Course } from './Course'

import kurzy from '../../../assets/img/kurzy.jpg?format=webp'
import workshopy from '../../../assets/img/workshopy.jpg?format=webp'
import { IOrder } from '../../../App/services/generic'

export const Courses = () => {

    const intl = useIntl()

    const { type } = useParams()
    let filter: string, title: string, titlePhoto: string
    if( type === 'pravidelne' ) {
        title = intl.formatMessage({ description: 'Kurzy nadpis', defaultMessage: 'Kurzy' })
        titlePhoto = kurzy.src
        filter = 'currentAndFutureRegular'
    } else {
        title = intl.formatMessage({ description: 'Workshopy nadpis', defaultMessage: 'Workshopy' })
        titlePhoto = workshopy.src
        filter = 'currentAndFutureOnetime'
    }

    const service = useMemo( () => new CoursesService(), [] )
    const order: IOrder = useMemo( () => (type === 'pravidelne' ? { rrule_data: 'ASC' as const, start: 'ASC' as const } : { name: 'ASC' as const, start: 'ASC' as const }), [ type ] ) as unknown as IOrder
    const { loading, error, rows } = useRows<EventModel>( service, { filter, order } )

    const cd = useMemo( () => new Date(), [] )
    const rd = useMemo( () => new Date( cd.getFullYear(), 5, 1 ), [ cd ] )
    const newRows = useMemo( () => rows?.filter( r => r.start > rd ) ?? [], [ rows, rd ] )
    const oldRows = useMemo( () => rows?.filter( r => r.start <= rd ) ?? [], [ rows, rd ] )

    const { hash } = useLocation()
    const courseId = hash ? hash.substring( 2 ) : '' // remove '#C'
    const courseRef = useRef<HTMLDivElement | null>( null )
    useEffect( () => {
        if( courseRef.current ) courseRef.current.scrollIntoView()
    }, [ rows ] )

    return(<>
        <AppPageHeading imgUrl={ titlePhoto } title={ title } />
        <AppStatus error={ error } loading={ loading } />
        <Row>
            <Col sm={ 12 } className="px-4 my-2">Vyhrazuji si právo všechny prezenční kurzy v jinak neřešitelných případech nahradit on-line formou kurzu (např. v případě celostátní karantény a příkazu zavřít studio). Zakoupením jakéhokoli mého kurzu souhlasíte s on-line formou kurzu.</Col>
            { rows?.length === 0 && <div>Aktuálně nejsou naplánovány žádné akce.</div> }
            { cd > rd && newRows.length > 0 
                ? newRows.map( r => <Course ref={ ( el ) => { if( r.eventId == courseId ) courseRef.current = el }} key={ r.eventId } row={ r } />) 
                : oldRows.map( r => <Course ref={ ( el ) => { if( r.eventId == courseId ) courseRef.current = el }} key={ r.eventId } row={ r } />) 
            }
            { cd > rd && oldRows.length > 0 && <>
                <hr />
                <Col sm={ 12 } className="my-2">Končící kurzy</Col>
                { oldRows.map( r => <Course ref={ ( el ) => { if( r.eventId == courseId ) courseRef.current = el }} key={ r.eventId } row={ r } />) }
            </> }
        </Row>
    </>)
}