import React, { forwardRef } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import { ButtonProps } from 'react-bootstrap'
import { useBootstrapPrefix } from 'react-bootstrap/ThemeProvider'

import classNames from 'classnames'

type LinkButtonProps = ButtonProps & LinkProps

export const LinkButton = forwardRef<HTMLAnchorElement, LinkButtonProps>( ( { as, bsPrefix, variant, size, active, className, to, ...props }, ref ) => {
    const prefix = useBootstrapPrefix(bsPrefix, 'btn')
    return <Link 
        { ...props }
        ref={ ref }
        className={ classNames(
            className,
            prefix,
            active && 'active',
            variant && `${prefix}-${variant}`,
            size && `${prefix}-${size}`,
            props.href && props.disabled && 'disabled',
        )}
        to={ to } 
    />
} )