import React, { useCallback, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAuth } from '../../../Auth'

interface ILogoutLinkProps {
    to?: string
    children: ReactNode
}

export const LogoutLink = ( { to, children }: ILogoutLinkProps ) => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const { logout } = useAuth() ?? { logout: () => {} }

    const navigate = useNavigate()

    const logoutClick = ( ev: React.MouseEvent<HTMLAnchorElement> ) => {
        ev.preventDefault()
        logout()
        navigate( to ?? '/' )
    }
    
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    return <a href="#" onClick={ logoutClick }>{ children }</a>
}