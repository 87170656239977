import { User, parseJwt, AuthData } from '../../..'

export const checkAuthenticated = <T extends User>( authData?: AuthData<T> ): number => {

    if( !authData || !authData.token || !authData.user ) return 0

    try {
        const tokenDecoded = parseJwt( authData.token )
        const nowMs = Date.now()
        if( !tokenDecoded.exp || tokenDecoded.exp * 1000 < nowMs ) return 0

        return tokenDecoded.exp * 1000
    } catch( error: unknown ) {
        return 0
    }
}

export const checkReAuthenticationToken = <T extends User>( authData: AuthData<T> ): boolean => {
    
    if( !authData.reToken ) return false

    try {
        const reTokenDecoded = parseJwt( authData.reToken )
        const nowMs = Date.now()
        if( reTokenDecoded.exp && reTokenDecoded.exp * 1000 < nowMs ) return false    
    } catch( error: unknown ) {
        return false
    }

    return true
}

export const getReAuthenticationInterval = <T extends User>( authData?: AuthData<T> ): number | undefined => {
    if( !authData ) return undefined

    const hasValidReAuthenticationToken = checkReAuthenticationToken( authData )
    if( !hasValidReAuthenticationToken ) return undefined

    const authenticationExpiry = checkAuthenticated( authData )
    if( authenticationExpiry > 0 ) return ( authenticationExpiry - Date.now() ) - 2 * 60 * 1000 // 2minutes before

    return 0
}

export const getUser = <T extends User>( authData?: AuthData<T> ): T | undefined => authData ? authData.user : undefined

export const hasRole = <T extends User>( authData?: AuthData<T>, roles?: string[] | string ): boolean => {

    if( !authData ) return false

    if( typeof roles === 'undefined' ) return true
    
    const user = getUser( authData )
    if( !user ) return false

    return typeof roles === 'string' ? roles === 'any' || user.roles.includes( roles ) : roles.reduce( ( auth, role ) => auth || user?.roles.includes( role ) || false, false )
}


export const getAuthHeader = <T extends User>( authData: AuthData<T> | string | undefined, reToken = false ): HeadersInit | undefined => {
    //const token = localStorage.getItem( 'token' );
    const token = typeof authData === 'string' ? authData : authData && authData.token ? ( reToken ? authData.reToken : authData.token ) : undefined
    return token ? { Authorization: 'Bearer ' + token } : undefined
}
