import { defineMessages } from 'react-intl'

export const CookieIntlMessages = defineMessages({
    acceptAll: {
        description: 'App.CookieBanner.AcceptAll',
        defaultMessage: 'Accept All'
    },
    declineAll: {
        description: 'App.CookieBanner.DeclineAll',
        defaultMessage: 'Decline All'
    },
    saveCustom: {
        description: 'App.CookieBanner.SaveCustom',
        defaultMessage: 'Save Settings'
    },
    customize: {
        description: 'App.CookieBanner.Setup', 
        defaultMessage: 'Customize'
    },
    customizeLink: {
        description: 'App.CookieBanner.SetupLink', 
        defaultMessage: 'Cookie preferences'
    },
    bannerHeading: {
        description: 'App.CookieBanner.Heading',
        defaultMessage: 'Podělíte se s námi o vaše cookies?'
    },
    bannerText: {
        description: 'App.CookieBanner.Text',
        defaultMessage: 'Ano, i náš web používá cookies. Díky povolení cookies vám budeme moct zprostředkovat nevšední zážitek při prohlížení našeho webu. Analytické cookies používáme k analýze návštěvnosti a personalizační nám pomáhají s přizpůsobením webu.'
    },
    detailHeader: {
        description: 'App.CookieBanner.Detail.Header',
        defaultMessage: 'My cookie preferences'
    },
    aboutCookiesTitle: {
        description: 'App.CookieBanner.About.Title;',
        defaultMessage: 'K čemu cookies slouží?'
    },
    aboutCookiesText: {
        description: 'App.CookieBanner.About.Text',
        defaultMessage: 'Cookies soubory zajišťují správné fungování základních funkcí našeho webu a přispívají k tomu, abyste si váš pobyt v online prostoru užívali. Níže si můžete vybrat, jaké cookies nám chcete nebo nechcete poskytnout. Svoji volbu můžete také kdykoliv změnit.'
    },
    cookieType_necessary: {
        description: 'App.CookieBanner.CookieType.Necessary',
        defaultMessage: 'Functional cookies'
    },
    cookieType_statistics: {
        description: 'App.CookieBanner.CookieType.Analytical',
        defaultMessage: 'Analytical cookies'
    },
    cookieType_preferences: {
        description: 'App.CookieBanner.CookieType.Personalization',
        defaultMessage: 'Personalization cookies'
    },
    cookieType_marketing: {
        description: 'App.CookieBanner.CookieType.Marketing',
        defaultMessage: 'Marketing cookies'
    },
})
