import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useCookieConsentContext } from '@use-cookie-consent/react'
import { useIntl } from 'react-intl'

import { CookieIntlMessages } from '../messages'
import { CookieDetail } from '../CookieDetail'
import { CookiePurposeType } from '../types'

type CookieBannerProps = {
    showBannerOnMount?: boolean
    supportedCookieTypes?: CookiePurposeType[]
}

export const CookieBanner = ( { supportedCookieTypes, showBannerOnMount }: CookieBannerProps ) => {

    const intl = useIntl()

    const { consent, acceptAllCookies } = useCookieConsentContext()

    const [ showDetail, setShowDetail ] = useState( false )
    const hideDetail = () => setShowDetail( false )

    // this prevents showing cookie banner on load
    // when usigng SSR - banner is always hidden and only shown once browser scripts are active
    const [ showBanner, setShowBanner ] = useState( showBannerOnMount || false )
    useEffect( () => {
        // run only once when compoent is mounted
        if( !showBanner ) setShowBanner( true )
    }, [] )

    return <>
        <CookieDetail supportedCookieTypes={ supportedCookieTypes } show={ showDetail } hide={ hideDetail }/>
        { showBanner && typeof consent.session === 'undefined' && <div className='fixed-bottom w-100 p-3 bg-cookie-banner'>
            <Row>
                <Col>
                    <h3>{ intl.formatMessage( CookieIntlMessages.bannerHeading ) }</h3>
                    <p>{ intl.formatMessage( CookieIntlMessages.bannerText ) }</p>
                </Col>
                <Col md='auto'>
                    <p><Button type='submit' className='w-100'  onClick={ () => acceptAllCookies() }>{ intl.formatMessage( CookieIntlMessages.acceptAll ) }</Button></p>
                    <p><Button className='w-100' onClick={ () => setShowDetail( true ) }>{ intl.formatMessage( CookieIntlMessages.customize ) }</Button></p>
                </Col>
            </Row>
        </div> }
    </>
}