import React from 'react'

import { AppStatusComponentProps } from '../../../Common'

import { AppLoading } from '../AppLoading'
import { AppMessage } from '../AppMessage'

export const AppStatus = ( { loading, error }: AppStatusComponentProps ) => (<>
    { error && <AppMessage variant="danger"><strong>Chyba! </strong>{ error }</AppMessage> }
    { loading && <AppLoading /> }
</>)