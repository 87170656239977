import React, { useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Col, Row } from 'react-bootstrap'

import { LoginMsg, useAuth, User } from '../../../Auth'
import { Form, Text, CheckBox, FormContextType, useUrlFrom, Password } from '../../../Forms'

import { PwdReset } from '../PwdReset'
import { useFocus } from '../../../Common'
import { useLogin } from '../../hooks/useLogin'

import { AUTH_CONFIG } from '../../contexts/AuthContextProvider'

export const Login = <T extends User>() => {

    const intl = useIntl()
    const title = intl.formatMessage({ description: 'auth.login.page-title', defaultMessage: 'Log-in' })

    const navigate = useNavigate()
    const { pathname } = useLocation()

    const auth = useAuth()

    const urlFrom = useUrlFrom()

    const [ loginRun, useLoginRequestState ] = useLogin<T>( urlFrom )

    const onFinish = ( form: FormContextType ) => loginRun( form.data[ 'username' ] as string, form.data[ 'pwd' ] as string )

    const body = 
        <>{ pathname === '/log-in/pwd-reset' ? <PwdReset />
            : <>
                { AUTH_CONFIG.pageHeading && <AUTH_CONFIG.pageHeading title={ title }>
                    { title }
                </AUTH_CONFIG.pageHeading> }
                { ! AUTH_CONFIG.pageHeading && <h1>
                    <FormattedMessage description='auth.login.page-title' defaultMessage='Log-in' />
                </h1> }
                { AUTH_CONFIG.pageStatus && <AUTH_CONFIG.pageStatus 
                    loading={ useLoginRequestState.processing }
                    error={ useLoginRequestState.isSuccess ? undefined : useLoginRequestState.message }
                /> }
                <Form 
                    formName='login'
                    initialData={{ username: '', pwd: '', rememberMe: false }}
                    onFinishCallback={ onFinish }
                >
                    <Text 
                        ref={ useFocus }
                        controlId='username' 
                        type='email'
                        className='mb-3'
                        placeholder={ intl.formatMessage({ description: 'auth.username', defaultMessage: 'Username' }) }
                        required
                        invalidMsg={ intl.formatMessage({ description: 'auth.username-required', defaultMessage: 'Please input your Username!' }) }
                    />
                    <Password 
                        controlId='pwd' 
                        className='mb-3'
                        placeholder={ intl.formatMessage( { description: 'auth.pwd', defaultMessage: 'Password' } ) } 
                        invalidMsg={ intl.formatMessage({ description: 'auth.pwd-required', defaultMessage: 'Please input your Password!' }) }
                    />
                    <Row className='mb-3'>
                        <Col size={ 12 } md={ 7 }>
                            <CheckBox 
                                controlId='rememberMe'
                                type='checkbox' 
                                label={ intl.formatMessage({ description: 'auth.login.remember-me', defaultMessage: 'Remember me' }) } 
                            />
                        </Col>
                        { /* Provide password reset link only if such service is defined */ }
                        { auth && typeof auth.pwdReset !== 'undefined' && <Col className='text-end' size={ 12 } md={ 5 }>
                            <Link className='login-form-forgot' to='/log-in/pwd-reset' state={ { from: urlFrom } }>
                                <FormattedMessage description='auth.login.forgot-pwd' defaultMessage='Forgot password' />
                            </Link>
                        </Col> }
                    </Row>
                    <Row className='mb-3'>
                        <Col>
                            <Button type='submit' className='login-form-button'>{ intl.formatMessage( LoginMsg ) }</Button>
                        </Col>
                        { /* Provide registration link only if such service is defined */ }
                        { auth && typeof auth.registerLink !== 'undefined' && <Col className='text-end'>
                            &nbsp; { auth.registerLink }
                        </Col> }
                    </Row>
                </Form>
            </>
        } </>
        
    return AUTH_CONFIG.viewWrapper ? <AUTH_CONFIG.viewWrapper>{ body }</AUTH_CONFIG.viewWrapper> : body
}