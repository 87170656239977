import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { AsyncState, useAsync } from '../../Common'
import { useNotifications } from '../../Notifications'

import { AuthOperResult, User } from '../types'
import { useAuth } from './useAuth'
import { useCallback, useRef } from 'react'

type UsePwdResetReturn<T extends User> = [
    ( username: string, urlFrom?: string ) => void,
    AsyncState<AuthOperResult<T>>
]

export const usePwdReset = <T extends User>( urlFrom?: string ): UsePwdResetReturn<T> => {

    const intl = useIntl()

    const navigate = useNavigate()

    const auth = useAuth()

    const { addNotification, removeAllNotifications } = useNotifications()

    const urlFromRef = useRef<string | undefined>( undefined )
    
    const pwdResetRequest = useAsync<AuthOperResult<User>>({
        onSuccess: ( state ) => {
            if( typeof state.data === 'undefined' || ! state.data.success || typeof state.data.status === 'undefined' || state.data?.status > 300 ) {
                addNotification({ variant: 'danger', category: 'auth', content: state.data?.message || intl.formatMessage( { description: 'auth.pwd-reset.error', defaultMessage: 'Password reset error. Try it once more.' } ) })
            } else {
                addNotification({ variant: 'warning', category: 'auth', content: intl.formatMessage( { description: 'auth.pwd-reset.sucess', defaultMessage: 'Password reset was registered. Look into your email for more information.' }), timeout: 8000 })
                navigate( '/log-in', { replace: true, state: urlFromRef.current || urlFrom } )
            }
            urlFromRef.current = undefined
        },
        onError: ( state ) => {
            addNotification({ variant: 'danger', category: 'auth', content: intl.formatMessage( { description: 'auth.pwd-reset.error', defaultMessage: 'Password reset error. Try it once more.' } ) })
            urlFromRef.current = undefined
        }
    })

    const run = useCallback( ( username: string, urlFrom?: string ) => {
        if( auth.pwdReset ) {
            if( ! pwdResetRequest.state.processing ) {
                urlFromRef.current = urlFrom
                removeAllNotifications( 'auth' )
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                pwdResetRequest.run({ fn: () => auth.pwdReset!( username ) })    
            }    
        } else throw new Error( 'Function pwdReset does not exits!' )
    }, [ auth.pwdReset, pwdResetRequest, removeAllNotifications ] )
    
    return [ run, pwdResetRequest.state as AsyncState<AuthOperResult<T>> ]
}