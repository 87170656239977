import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedTime } from 'react-intl'

import { EventCal as EventCalModel } from '../../../../../../cz.samaya.api/src/_api-models/index'

type CourseCalProps = {
    row: EventCalModel
}

export const CourseCal = ( { row }: CourseCalProps ) => {

    return(
        <div className="text-box">
            <Link to={ '/kurzy/' + ( row.tags=='regular' ? 'pravidelne' : 'workshopy' ) + '#C' + String( row.eventId ) }>
                <div><FormattedTime value={ row.start } /> - <FormattedTime value={ row.end } /></div>
                <div>{ row.name }</div>
            </Link>
        </div>
    )
}
