import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'

import { useReducerWithCallback } from './useReducerWithCallback'
import { useHttpRequestContext } from './useHttpRequestContext'

import { fetchExt } from '../utils/fetchExt'

type MethodType = 'GET' | 'POST' | 'PUT' | 'DELETE'
type ContentType = 'application/json' | 'text/html'

type HttpRequestOptions<HttpRequestDataType> = {
    url: string
    method: MethodType
    contentType?: ContentType
    data?: HttpRequestDataType
    label?: string
    getAuthHeader?: () => HeadersInit | undefined
    runDeps?: React.DependencyList
    noAutoRun?: boolean
    noAbort?: boolean
    timeout?: number
    cacheKey?: string
    cacheExpiry?: number
}

type UseHttpRequestProps<HttpRequestDataType, AppType, APIType = AppType> = HttpRequestOptions<HttpRequestDataType> & {
    onData?: ( data: APIType ) => AppType
    onSuccess?: ( state: AsyncHttpRequestState<HttpRequestDataType, AppType, APIType> ) => void
    onError?:  ( state: AsyncHttpRequestState<HttpRequestDataType, AppType, APIType> ) => void
    onTimeout?:  ( state: AsyncHttpRequestState<HttpRequestDataType, AppType, APIType> ) => void
    onAbort?:  ( state: AsyncHttpRequestState<HttpRequestDataType, AppType, APIType> ) => void
}

type HttpRequestRunProps<HttpRequestDataType, AppType, APIType = AppType> = Partial<Omit<UseHttpRequestProps<HttpRequestDataType, AppType, APIType>, 'noAbort' | 'runOn'>>

type HttpRequestResult<AppType> = {
    success: boolean
    message: string
    data?: AppType
    errorCode?: string
} 

type HttpRequestReturn<HttpRequestDataType, AppType, APIType = AppType> = {
    state: AsyncHttpRequestState<HttpRequestDataType, AppType, APIType>,
    dispatchState: React.Dispatch<AsyncHttpRequestStateAction<HttpRequestDataType, AppType, APIType>>
    run: ( options: HttpRequestRunProps<HttpRequestDataType, AppType, APIType> ) => { ( reason?: unknown  ): void } | boolean
    abort: () => void
}

type AsyncHttpRequestStateActionType = 'init' | 'run' | 'error' | 'success' | 'timeout' | 'abort' | 'finish'

export type AsyncHttpRequestState<HttpRequestDataType, AppType, APIType = AppType> = {
    phase: AsyncHttpRequestStateActionType
    options?: UseHttpRequestProps<HttpRequestDataType, AppType, APIType>
    processing: boolean
    success?: boolean
    status?: number
    message?: string
    data?: AppType
    errorCode?: string
}

type AsyncHttpRequestStateAction<HttpRequestDataType, AppType, APIType = AppType> = 
    { type: 'init' } 
    | { type: 'run', payload: { options: UseHttpRequestProps<HttpRequestDataType, AppType, APIType> } }
    | { type: 'error', payload: { status: number, message: string, errorCode?: string } }
    | { type: 'success', payload: { status: number, message: string, data: AppType, options?: UseHttpRequestProps<HttpRequestDataType, AppType, APIType> }}
    | { type: 'timeout' }
    | { type: 'abort' }
    | { type: 'finish' }

const AsyncHttpRequestStateInit = <HttpRequestDataType, AppType, APIType = AppType>(): AsyncHttpRequestState<HttpRequestDataType, AppType, APIType> => ({
    phase: 'init',
    processing: false
})
    
const asyncHttpRequestStateReducer = <HttpRequestDataType, AppType, APIType = AppType>( state: AsyncHttpRequestState<HttpRequestDataType, AppType, APIType>, action: AsyncHttpRequestStateAction<HttpRequestDataType, AppType, APIType> ): AsyncHttpRequestState<HttpRequestDataType, AppType, APIType> => {
    switch( action.type ) {
    case 'init':
        return { ...AsyncHttpRequestStateInit() }
    case 'run': 
        return { ...state, phase: action.type, options: action.payload.options, processing: true }
    case 'error':
        return { ...state, phase: action.type, processing: false, success: false, status: action.payload.status, message: action.payload.message, data: undefined, errorCode: action.payload.errorCode }
    case 'success':
        return { ...state, phase: action.type, processing: false, success: true, status: action.payload.status, message: action.payload.message, data: action.payload.data, ...( action.payload.options ? { options: action.payload.options } : undefined ) }
    case 'timeout':
        return { ...state, phase: action.type, processing: false, success: undefined }
    case 'abort':
        return { ...state, phase: action.type, processing: false, success: undefined }
    case 'finish':
        return { ...state, phase: action.type }    
    default:
        throw new Error(`Unhandled action type - ${ JSON.stringify( action ) }`)
    }
}

const useAsyncHttpRequest = <HttpRequestDataType, AppType, APIType = AppType>( initOptions: UseHttpRequestProps<HttpRequestDataType, AppType, APIType> ): HttpRequestReturn<HttpRequestDataType, AppType, APIType> => {

    const intl = useIntl()

    const abortRef = useRef<( reason?: unknown ) => void >()

    const httpRequestContext = useHttpRequestContext()

    // normlaize initOptions
    initOptions = { contentType: 'application/json', ...initOptions }
    
    // need to preserve optiosn as state to be able to modify them on per run basis
    // some defaults are fueled in
    const [ options, setOptions ] = useState<UseHttpRequestProps<HttpRequestDataType, AppType, APIType>>()

    const [ state, dispatchState ] = useReducerWithCallback<AsyncHttpRequestState<HttpRequestDataType, AppType, APIType>, AsyncHttpRequestStateAction<HttpRequestDataType, AppType, APIType>>( asyncHttpRequestStateReducer, AsyncHttpRequestStateInit() )
    const reducerCallback = ( state: AsyncHttpRequestState<HttpRequestDataType, AppType, APIType> ) => {
        if( state.phase === 'success' ) state.options?.onSuccess?.( state )
        else if( state.phase === 'error' ) state.options?.onError?.( state ) 
        else if( state.phase === 'timeout' ) state.options?.onTimeout ? state.options?.onTimeout( state ) : state.options?.onError?.( state )
        else if( state.phase === 'abort' ) state.options?.onAbort ? state.options?.onAbort( state ) : state.options?.onError?.( state )
        dispatchState({ type: 'finish' })
    }

    const runAsync = useCallback( ( calcOptions: UseHttpRequestProps<HttpRequestDataType, AppType, APIType> ) => {

        setOptions( calcOptions )
        dispatchState({ type: 'run', payload: { options: calcOptions } })

        const [ promise, abort ] = fetchExt<HttpRequestResult<APIType>, HttpRequestDataType>( calcOptions.url, {
            method: calcOptions.method,
            headers: { 
                // add authetication headers only when required
                ...( calcOptions.getAuthHeader ? calcOptions.getAuthHeader() : undefined ), 
                'Content-Type': calcOptions.contentType || 'application/json',
                'Accept': 'application/json',
                'Accept-Language': `${ intl.locale }, ${ intl.locale.substring( 0, 2 ) };q=0.9, ${ navigator.languages.join( ',' ) }`
            },
            timeout: calcOptions.timeout,
            data: calcOptions.data
        } )

        promise
            .then( result => {
                if( result.status >= 200 && result.status < 400 && result.bodyData.success ) {
                    let data = null
                    // save to cache - storing data in ApiType to allow reprocess it in onData
                    if( calcOptions.cacheKey && calcOptions.cacheExpiry && result.bodyData.data ) {
                        httpRequestContext.cache.set( calcOptions.cacheKey, { data: result.bodyData.data, expiry: new Date( (new Date()).getTime() + calcOptions.cacheExpiry ) } )
                    }
                    // process data 
                    if( result.bodyData.data && calcOptions.onData ) data = calcOptions.onData( result.bodyData.data )
                    else data = result.bodyData.data as unknown as AppType
                    // finalize HttpRequest
                    dispatchState({ 
                        type: 'success', 
                        payload: { 
                            status: result.status, 
                            message: result.bodyData.message, 
                            data
                        }
                    }, reducerCallback )
                }
                else {
                    // in case of any error delete anything in cache
                    if( calcOptions.cacheKey ) httpRequestContext.cache.delete( calcOptions.cacheKey )
                    dispatchState({ type: 'error', payload: 
                        result.status === 503 ? { status: result.status, message: 'Service not available. Try it please later.' } :
                            result.status === 401 ? { status: result.status, message: 'Un-authenticated access' } :
                                { status: result.status, message: result.bodyData.message, errorCode: result.bodyData.errorCode }
                    }, reducerCallback )
                }
            })
            .catch( ( error: unknown ) => {
                console.error( String( error ) )
                // in case of any error delete anything in cache
                if( calcOptions.cacheKey ) httpRequestContext.cache.delete( calcOptions.cacheKey )
                if( error instanceof DOMException )
                    if( error.name === 'FETCH-TIMEOUT' ) 
                        dispatchState({ type: 'timeout' }, reducerCallback )
                    else
                        dispatchState({ type: 'abort' }, reducerCallback )
                else dispatchState({ type: 'error', payload: { status: 599, message: 'Service not available. Try it please later.' } }, reducerCallback )
            } )

        if( typeof abort === 'undefined' ) throw new Error( 'Unknown error in useHttpRequest' )

        return abort
    // dispatch state is guaranteed to be persistent (feature of the hook)
    }, [ state ] )

    const run = ( runOptions?: HttpRequestRunProps<HttpRequestDataType, AppType, APIType> ) => {
        // in case the HttpRequest is being processed - do not start once more
        if( state.processing ) return false
        // setting options is not usable for this function as it is asynchrnous - we need interim value to run
        // and only then persist value
        const calcOptions = { ...httpRequestContext.options, ...initOptions, ...runOptions }
        //console.log( `useHttpRequest.run: label: ${ initOptions.label }; url: ${ calcOptions.url }` )
        // check cache and if in cache and expiry date not passed return cache
        if( calcOptions.cacheKey ) {
            const cacheData = httpRequestContext.cache.get<APIType>( calcOptions.cacheKey )
            if( cacheData) {
                if( cacheData.expiry > new Date() ) {
                    // re-process data 
                    let data
                    if( calcOptions.onData ) data = calcOptions.onData( cacheData.data )
                    else data = cacheData.data as unknown as AppType
                    dispatchState({ 
                        type: 'success', 
                        payload: { 
                            status: 304, // Not Modified 
                            message: '', 
                            data,
                            // options are needed for state processing...
                            options: calcOptions
                        }
                    }, reducerCallback )
                    return true
                }
                // if cache expirted - remove from cache
                else httpRequestContext.cache.delete( calcOptions.cacheKey )
            }
        }
        // if cache could not be used - start new run
        abortRef.current = runAsync( calcOptions )
        return ! calcOptions.noAbort ? abortRef.current : true
    }

    // here we use options which maintains current options in time running
    const abort = useCallback( ( reason?: unknown, unmouting = false ) => {
        // abort can be used only when processing is running
        if( state.processing && options && !options.noAbort ) {
            if( !unmouting ) dispatchState({ type: 'abort' })
            abortRef.current?.( reason )
            // clear after processing
            abortRef.current = undefined
        }
    // dispatch state is guaranteed to be persistent (feature of the hook)
    }, [ state, options, options?.noAbort ])

    // automatic run effect
    useEffect( () => {
        //console.log( `useHttpRequest.useEffect: label: ${ initOptions.label }; url: ${ initOptions.url }; noAutoRun: ${ initOptions.noAutoRun }` )

        // run immediatelly in case runOn is true or there are some dependencies defined
        // as far as this might be triggered twice - we check state
        if( ! state.processing && !initOptions.noAutoRun ) void run()
        return () => abort( 'Automatic abort when unmouting component', true )
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, typeof initOptions.runDeps === 'undefined' ? [] : initOptions.runDeps )

    //console.log( `useHttpRequest: label: ${ initOptions.label }; url: ${ initOptions.url }; runDeps: ${ initOptions.runDeps }; noAutoRun: ${ initOptions.noAutoRun }` )

    return { state, dispatchState, run, abort }
}


type UseFetchProps<HttpRequestDataType, AppType, APIType = AppType> = Omit<UseHttpRequestProps<HttpRequestDataType, AppType, APIType>, 'method' | 'body' | 'runOn'> & {
    method?: 'GET' | 'POST'
    data?: HttpRequestDataType
}

type useFetchAll = {
    <AppType>({ url, method, data, ...props }: UseFetchProps<unknown, AppType, AppType>,  runDeps?: React.DependencyList ): HttpRequestReturn<unknown, AppType, AppType>
    <HttpRequestDataType, AppType, APIType = AppType>({ url, method, data, ...props }: UseFetchProps<HttpRequestDataType, AppType, APIType>,  runDeps?: React.DependencyList ): HttpRequestReturn<HttpRequestDataType, AppType, APIType>
}

export const useFetch: useFetchAll = <HttpRequestDataType, AppType, APIType = AppType>({ url, method = 'GET', data, ...props }: UseFetchProps<HttpRequestDataType, AppType, APIType>,  runDeps?: React.DependencyList ): HttpRequestReturn<HttpRequestDataType, AppType, APIType> => {

    if( data ) {
        if( method === 'GET' ) {
            const dataNomralized = Object.fromEntries( Object.entries( data ).map( e => [ e[ 0 ], String( e[ 1 ] ) ] ) )
            url = `${ url }?${ new URLSearchParams( dataNomralized ).toString() }`
        }
    }

    return useAsyncHttpRequest({ url, method, data, runDeps, ...props } )
}

type UseChangeProps<HttpRequestDataType, AppType, APIType = AppType> = Omit<UseHttpRequestProps<HttpRequestDataType, AppType, APIType>, 'method' | 'body' | 'runOn'> & {
    method?: MethodType
    data?: HttpRequestDataType
}

type useChangeAll = {
    <AppType>({ method, data, ...props }: UseChangeProps<unknown, AppType, AppType>,  runDeps?: React.DependencyList ): HttpRequestReturn<unknown, AppType, AppType>
    <HttpRequestDataType, AppType, APIType = AppType>({ method, data, ...props }: UseChangeProps<HttpRequestDataType, AppType, APIType>,  runDeps?: React.DependencyList ): HttpRequestReturn<HttpRequestDataType, AppType, APIType>
}

export const useChange: useChangeAll = <HttpRequestDataType, AppType, APIType = AppType>({ method = 'PUT', data, ...props }: UseChangeProps<HttpRequestDataType, AppType, APIType>,  runDeps?: React.DependencyList ): HttpRequestReturn<HttpRequestDataType, AppType, APIType> => {
    return useAsyncHttpRequest({ method, data, runDeps, noAutoRun: true, ...props } )
}

