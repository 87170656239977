import { useCallback, useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { AsyncState, useAsync } from '../../Common'

import { useNotifications } from '../../Notifications'
import { AUTH_CONFIG } from '../contexts/AuthContextProvider'

import { AuthOperResult, User } from '../types'
import { useAuth } from './useAuth'

type UseLoginReturn<T extends User> = [
    ( username: string, pwd: string, urlFrom?: string ) => void,
    AsyncState<AuthOperResult<T>>
]

export const useLogin = <T extends User>( urlFrom?: string ): UseLoginReturn<T> => {

    const intl = useIntl()
    
    const navigate = useNavigate()

    const auth = useAuth()

    const { addNotification, removeAllNotifications } = useNotifications()

    const urlFromRef = useRef<string | undefined>( urlFrom )

    //const [ rememberMe, setRememberMe ] = useState( false )
    const loginRequest = useAsync<AuthOperResult<User>>({
        onSuccess: ( state ) => {
            if( typeof state.data === 'undefined' || ( ! state.data.success && ( typeof state.data.status === 'undefined' || state.data?.status > 300 ) ) ) {
                addNotification({ variant: 'danger', category: 'auth', content: state.data?.message || intl.formatMessage( { description: 'auth.login.error', defaultMessage: 'Login error. Try it once more.' } ) })
            } else {
                addNotification({ variant: 'success', category: 'auth', content: intl.formatMessage({ description: 'auth.login.success', defaultMessage: 'You were succesfully loged-in!' }), timeout: 2000 })
            }
            urlFromRef.current = undefined
        },
        onError: ( state ) => {
            addNotification({ variant: 'danger', category: 'auth', content: state.data?.message || intl.formatMessage( { description: 'auth.login.error', defaultMessage: 'Login error. Try it once more.' } ) })
            urlFromRef.current = undefined
        }
    
    })

    const run = useCallback( ( username: string, pwd: string, urlFrom?: string  ) => {
        if( ! loginRequest.state.processing ) {
            urlFromRef.current = urlFrom || urlFromRef.current
            removeAllNotifications( 'auth ')
            loginRequest.run({ fn: () => auth?.login( username, pwd ) })    
        }
    }, [ auth, loginRequest, removeAllNotifications ] )

    // do navigate only after authetication really changed
    useEffect( () => {
        if( auth.isAuthenticated ) navigate( urlFromRef.current || urlFrom || AUTH_CONFIG.loginHome || '/', { replace: true } )
    }, [ auth, navigate ])

    return [ run, loginRequest.state  as AsyncState<AuthOperResult<T>>]
}