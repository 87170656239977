import React from 'react'
import loadable from '@loadable/component'

import { Home } from './components/Home'
import { News } from '../Samaya/components/News'
import { Pages } from '../Samaya/components/Pages'
import { Courses } from '../Samaya/components/Courses'
import { Lectors } from '../Samaya/components/Lectors'
import { CoursesCal } from '../Samaya/components/CoursesCal'
const Gallery = loadable( () => import( '../Samaya/components/Gallery' ) )
import { Order } from '../Samaya/components/Order'
import { SpecialOffer } from '../Samaya/components/SpecialOffer'

import AUTH_ROUTES from '../Auth/routes'
import { Route } from '../Common'

const AdminNewsEdit = loadable( () => import( '../Admin/components/NewEdit' ), { ssr: false } )
const AdminLectorEdit = loadable( () => import( '../Admin/components/LectorEdit' ), { ssr: false } )

export const ROUTES: Route[] = [
    {
        key: 'home',
        route: '/',
        label: 'domů',
        element: <Home />,
        isAuthenticated: false,
        prerender: {
            imageUrl: 'htts://cdn.samaya.cz/logo-new-web.png',
            langSpecifics: {
                'cs-CZ': {
                    pageTitle: 'Samaya.cz',
                    pageDescription: 'Nejdéle fungující jógové studio v centru Berouna.'
                },
                'en-US': {
                    pageTitle: 'Samaya.cz',
                    pageDescription: 'Longest running yoga studio in Beroun.'
                }
            }
        }
    },
    {
        key: 'news',
        route: '/aktuality/:type',
        label: 'Aktuality',
        element: <News />,
    },
    {
        key: 'pages',
        route: '/p/:page',
        label: 'Static Pages',
        element: <Pages />
    },
    {
        key: 'courses',
        route: '/kurzy/:type',
        label: 'Kurzy a Workshopy',
        element: <Courses />
    },
    {
        key: 'courses-cal',
        route: '/rozvrh',
        label: 'Rozvrh',
        element: <CoursesCal />
    },
    {
        key: 'lectors',
        route: '/lektori',
        label: 'Lektoři',
        element: <Lectors />
    },
    {
        key: 'galley',
        route: '/galerie',
        label: 'Galerie',
        element: <Gallery />
    },
    {
        key: 'order',
        route: '/order/:courseId',
        label: 'Objednávka',
        element: <Order />
    },
    {
        key: 'special-offer',
        route: '/special-offer',
        label: 'Speciální nabídka',
        element: <SpecialOffer />
    },
    {
        key: 'adminNews',
        route: '/admin/news/:highlightId',
        label: 'Edit: Aktualita',
        element: <AdminNewsEdit />,
        isAuthenticated: true
    },
    {
        key: 'adminLectors',
        route: '/admin/lector/:lectorId',
        label: 'Edit: Lektor',
        element: <AdminLectorEdit />,
        isAuthenticated: true
    },
    ...AUTH_ROUTES
]
