import React from 'react'

import { AppPageHeading } from '../../../../App/components/AppPageHeading'

import { Page as PageModel } from '../../../../../../cz.samaya.api/src/_api-models/index'

type PageProps = {
    row: PageModel
}

export const Page = ( { row }: PageProps ) => {
    return(
        <div>
            <AppPageHeading imgUrl={ row.images[ 0 ].filename } title={ row.title } />
            <div className="row d-block text-left grid-block">
                <div dangerouslySetInnerHTML={{ __html: row.content || '' }}></div>
            </div>
        </div>
    )
}