import { useEffect, useState, useCallback } from 'react'

//import { IModelBase } from '../../../_models/types'
import { GenericService, GetAllOptions } from '../services/generic'

type Phase = 'idle' | 'loading' | 'done' | 'error'

export const useRows = <T> ( service: GenericService<T>, { key, fields, where, order, filter}: GetAllOptions, run = true ) => {

    const [ error, setError ] = useState( '' )
    const [ phase, setPhase ] = useState<Phase>( 'idle' )
    const [ rows, setRows ] = useState<T[]>( [] )

    const getRows = useCallback( async () => {
        setPhase( 'loading' )
        try {
            const rows = await service.getAll({ key, fields, where, order, filter: filter || '' })
            setRows( rows )
            setPhase( 'done' )
        } catch( err: unknown ) {
            // eslint-disable-next-line @typescript-eslint/no-base-to-string
            if( typeof err === 'object' && typeof err?.toString === 'function' ) setError( err.toString() )
            setPhase( 'error' )
        }
    }, [ key, fields, filter, order, service, where ] )

    useEffect( () => {
        if( run )  void getRows()
    }, [ run, getRows ] )

    return { loading: phase === 'loading', phase, error, rows, run: getRows }
}