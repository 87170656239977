import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { AppStatus } from '../../../App/components/AppStatus'
import { useRows } from '../../../App/hooks/useRows'

import { PagesService } from '../../services/pages'
import { Page as PageModel } from '../../../../../cz.samaya.api/src/_api-models/index'
import { Page } from './Page'

export const Pages = () => {

    const { page } = useParams()

    const service = useMemo( () => new PagesService(), [] )
    const order = useMemo( () => ({ modified: 'DESC' as const }), [] )
    const { loading, error, rows } = useRows<PageModel>( service, { key: page, order } )
    
    return(<>
        <AppStatus error={ error } loading={ loading } />
        { rows.map( r => <Page key={ r.pageId } row={ r } /> ) }
    </>)
}
