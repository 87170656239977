import React from 'react'

import DatePickerOrig, { registerLocale } from 'react-datepicker'
import { cs } from 'date-fns/locale'
registerLocale( 'cs', cs )

import { useOnChange } from '../../hooks/useOnChange'

type DatePickerProps = {
    controlId: string
}

export const DatePicker = ( { controlId, ...props }: DatePickerProps ) => {

    const { form, onChange } = useOnChange( controlId, 'value' )

    return(<DatePickerOrig
        locale='cs'
        showPopperArrow={false}
        todayButton="Vandaag"
        timeInputLabel="Time:"
        timeIntervals={60}
        dateFormat="d.M.yyyy hh:mm"
        showWeekNumbers
        showTimeInput
        isClearable
        selected={ form ? form.data[ controlId ] as Date : new Date() }
        onChange={ dt => console.log( dt ) }
        onChangeRaw={ onChange }
        { ...props }
    />)
}