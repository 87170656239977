import React, { useEffect, useState } from 'react'
import { Button, Col, Modal, Row, Form } from 'react-bootstrap'
import { useCookieConsentContext } from '@use-cookie-consent/react'
import { useIntl } from 'react-intl'

import { CookieIntlMessages } from '../messages'
import { CookiePurposeType } from '../types'

export const CookiePurposeAll: CookiePurposeType[] = [ 'necessary', 'statistics', 'preferences', 'marketing' ]
type CookieDetailProps = {
    supportedCookieTypes?: CookiePurposeType[]
    show?: boolean
    hide: () => void
}

export const CookieDetail = ( { supportedCookieTypes, hide, show = true }: CookieDetailProps ) => {

    const intl = useIntl()

    const { consent, acceptCookies, acceptAllCookies, declineAllCookies } = useCookieConsentContext()
    const [ formConsent, setFormConsent ] = useState({ ...consent })

    const detailDeclineAll = () => {
        declineAllCookies()
        hide()
    }

    const detailSaveCustom = () => {
        acceptCookies({ ...formConsent, session: false })
        hide()
    }

    const detailAcceptAll = () => {
        acceptAllCookies()
        hide()
    }

    // reflect external changes in consent
    // this also handles accept/decline All
    useEffect( () => {
        setFormConsent({ ...consent })
    }, [ consent ])

    return <Modal backdrop='static' centered show={ show }>
        <Modal.Header>
            <Modal.Title>{ intl.formatMessage( CookieIntlMessages.detailHeader )}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h3>{ intl.formatMessage( CookieIntlMessages.aboutCookiesTitle ) }</h3>
            <p>{ intl.formatMessage( CookieIntlMessages.aboutCookiesText ) }</p>
            { (supportedCookieTypes || CookiePurposeAll).map( type => {
                return <div key={ type } className='bg-cookie-detail p-2 my-1'>
                    <Form.Check 
                        type='switch' 
                        id={ `accept-${ type }` } 
                        reverse
                    >
                        <Form.Check.Label style={{ width: '100%', textAlign: 'left' }}>{ 
                            intl.formatMessage( CookieIntlMessages[ `cookieType_${ type }` ])
                        }</Form.Check.Label>
                        <Form.Check.Input 
                            checked={ formConsent[ type ] }
                            onChange={ () => {
                                const newConsent = {
                                    ...formConsent
                                }
                                newConsent[ type ] = !formConsent[ type ]
                                setFormConsent( newConsent ) 
                            }}
                            disabled={ type === 'necessary' } 
                        />
                    </Form.Check>
                </div>
            })}
        </Modal.Body>
        <Modal.Footer>
            <Row className='w-100 g-0'>
                <Col sm='auto' className='text-end mt-1 order-md-last'>
                    <Button className='w-100' onClick={ detailAcceptAll }>{ intl.formatMessage( CookieIntlMessages.acceptAll ) }</Button>
                </Col>
                <Col className='text-start mt-1 d-flex d-md-block justify-content-evenly'>
                    <Button onClick={ detailDeclineAll }>{ intl.formatMessage( CookieIntlMessages.declineAll )}</Button>
                    <Button className='mx-1' onClick={ detailSaveCustom }>{ intl.formatMessage( CookieIntlMessages.saveCustom )}</Button>
                </Col>
            </Row>
        </Modal.Footer>
    </Modal>
}