import React, { createContext, forwardRef, useCallback, useMemo, useState } from 'react'
import { Form as BSForm } from 'react-bootstrap'

import { useUrlFrom } from '../../hooks/useUrlFrom'

import { DataType, FormContextType } from '../../types'

export const FormContext = createContext<FormContextType | undefined>( undefined )

type FormProps = {
    formName: string
    onFinishCallback: ( form: FormContextType, submitter: HTMLInputElement ) => Promise<void> | void
    className?: string
    initialData: Partial<DataType>
    children: React.ReactNode
}

export const Form = forwardRef<HTMLFormElement, FormProps>(( { formName, onFinishCallback, className, initialData, children }: FormProps, ref ) => {
    
    const urlFrom = useUrlFrom()

    const [ processing, setProcessing ] = useState( false )
    const [ validated, setValidated ] = useState( false )
    const [ data, setData ] = useState<DataType>( initialData as DataType )

    const setDataCallback = useCallback( ( value: DataType ) => {
        setData({ ...data, ...value })
    }, [ data, setData ] )

    const contextValue = useMemo( () => ({ processing, validated, urlFrom, data, setData: setDataCallback }), [ processing, validated, urlFrom, data, setDataCallback ] )

    const onFinish = useCallback( async ( ev: React.FormEvent<HTMLFormElement> ) => {
        setProcessing( true )
        ev.preventDefault()
        const form = ev.currentTarget
        const submitter = (ev.nativeEvent as SubmitEvent).submitter as HTMLInputElement
        if( ! form.checkValidity() ) {
            setValidated( true )
        } else {
            setValidated( true )
            await onFinishCallback( { ...contextValue, processing: true, validated: true }, submitter )
        }
        setProcessing( false )
    }, [ contextValue, onFinishCallback ] )

    return(<FormContext.Provider value={ contextValue }>
        <BSForm 
            ref={ ref }
            noValidate
            validated={ validated }
            name={ formName }
            className={ [ `${ formName.replace( '_', '-' ) }-form`, 'mx-auto', className || '' ]. join( ' ' ) }
            onSubmit={ ( ev ) => void onFinish( ev ) }
        >
            { children }
        </BSForm>
    </FormContext.Provider>)
})