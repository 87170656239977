import React from 'react'

import { Login } from './components/Login'
import { PwdReset } from './components/PwdReset'
import { LogoutLink } from './components/LogoutLink'

import { Route } from '../Common'

const ROUTES: Route[] = [
    {
        key: 'pwd-reset',
        route: '/log-in/pwd-reset',
        label: 'reset password',
        element: <PwdReset />,
        isAuthenticated: false
    },
    {
        key: 'login',
        route: '/log-in',
        label: 'log-in',
        element: <Login />,
        isAuthenticated: false
    },
    {
        key: 'logout',
        route: '/log-out',
        label: 'log-out',
        link: <LogoutLink>Log-out</LogoutLink>,
        isAuthenticated: true
    }
]

export default ROUTES