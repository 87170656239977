import { useMemo  } from 'react'

type UseQueryString = {
    (): URLSearchParams
    ( key: string ): string | null
}

export const useQueryString: UseQueryString = ( key?: string ): any => {
    
    // in case of server render window does not exists
    const search = typeof window !== 'undefined' ? window.location.search : ''
    const qs = useMemo( () => new URLSearchParams( search ), [ search ] )

    return key ? qs.get( key ) : qs
}
  