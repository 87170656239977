import { GenericService } from '../../App/services/generic'

import { EventCal } from '../../../../cz.samaya.api/src/_api-models/index'

export class CoursesCalService extends GenericService<EventCal> {
    constructor() { super( '/events/calendar/' ) }

    public parse( item: EventCal ) {

        item.start = new Date( item.start as unknown as string )
        item.end = new Date( item.end as unknown as string )

        item.dt = new Date( item.dt as unknown as string )

        item.modified = new Date( item.modified as unknown as string )

        return item
    }
}
