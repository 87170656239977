import styled from 'styled-components'

export const ButtonAsLink = styled.a({
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    textDecoration: 'underline',
    display: 'inline',
    margin: 0,
    padding: 0
})