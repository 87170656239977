import React from 'react'
import { Button } from 'react-bootstrap'

import { AppPageHeading } from '../../../App/components/AppPageHeading'
import { AppStatus } from '../../../App/components/AppStatus'
import { useChange } from '../../../Common'
import { Form, FormContextType, Text } from '../../../Forms'

export const SpecialOffer = () => {

    const sendRequest = useChange({ url: '' })

    const onFinish = ( form: FormContextType ) => {
        sendRequest.run({ data: { email: form.data.email } })
    }

    return <>
        <AppPageHeading title='' />
        <div className="heading">
            <p>LÍBIT SE SAMA SOBĚ V&nbsp;ZRCADLE A CÍTIT SE SEBEJISTĚ?</p>
            <p>NOSIT UPNUTÉ ŠATY A MÍT ŠMRNC?</p>
            <p>MÍT DOBROU NÁLADU A HODNĚ ENERGIE, I CHUŤ NA SEX?</p>
        </div>

        <figure>
            <img width="600" height="400" src="image002_600x400.jpg" alt="Pozice velbloud" />
        </figure>

        <p className="heading">MÁME PRO VÁS ZARUČENOU CESTU!</p>
        <p>Testujeme ji na sobě a stovkách klientek v Berouně už přes 10 let.</p>
        <p>Je to metoda známá tisíce let, která milióny lidí odměňuje mladistvým, zdravým tělem a větším sebevědomím.</p>

        <p>&nbsp;</p>

        <p>Chcete mít, stejně jako ženy po celém světě, kouzelný nástroj pro svou krásu a zdraví?</p>
        <p>Chcete mít štíhlé svaly, jiskru v oku a cítit se sexy?</p>
        <p className="heading">PAK SE OD ZÁŘÍ PŘIDEJTE DO NAŠICH LEKCÍ JÓGY V CENTRU BEROUNA!</p>
        <p>Proč? Protože jóga dělá hezká těla! A šťastné a spokojené ženy. Prošly nám jich rukama stovky a mnohé z nich k nám chodí po celou dobu existence Samaya yogy a s nimi jejich další a další kamarádky – a pozor, i partneři, protože některé naše lekce jsou i pro muže!</p>


        <figure>
            <img width="600" height="400" src="image004_600x400.jpg" alt="Pozice palma" />
        </figure>

        <ul>
            <li>na našich lekcích jógy zpevníte celé své tělo, ať už po porodu nebo po mnoha letech sezení v kanceláři</li>
            <li>máknete si a budete ze sebe mít radost, jak se týden od týdne posouváte</li>
            <li>zlepší se vám celková kondice</li>
            <li>najdete skvělou partu sympatických žen, protože k nám chodí jen pohodářky</li>
            <li>zjistíte, co všechno ve vás je a budete na sebe pyšná</li>
            <li>budete schopná cvičit i sama doma</li>
            <li>budete se cítit pružná, elegantní, krásná a plná energie</li>
        </ul>

        <p>&nbsp;</p>

        <div><a href="https://www.samaya.cz/rozvrh" className="btn">VYBERTE SI TU PRAVOU LEKCI PRO SEBE!</a></div>

        <p>&nbsp;</p>

        <p>A moc neotálejte, 3 kurzy jsou plně vyprodané, ve zbylých je už jen několik míst. A do září budou fuč. Protože tak to u nás prostě je každou sezónu 😊</p>

        <p></p>
        <p>&nbsp;</p>

        <Form
            formName='special-offer'
            initialData={{ email: '' }}
            onFinishCallback={ onFinish }
        >
            <AppStatus error={ sendRequest.state.message || '' } />
            <p>Chcete vidět, jak naše lekce vypadají? Řekněte si o videolekci zdarma.</p>
            <Text controlId='email' required placeholder="Chci poslat videolekci na tento email" />
            <Button type='submit' name='send-special-offer'>POŠLETE MI LEKCI ZDARMA</Button>
        </Form>

        <figure>
            <img width="400" height="600" src="image006_400x600.jpg" alt="Pozice vrána" />
        </figure>

        <div className="small">
            <p>A kdy pro vás naše lekce nejsou vhodné?</p>
            <ul>
                <li>Pokud máte závažné zdravotní omezení (zavolejte, poradíme)</li>
                <li>Pokud jste naprostý začátečník a chcete se spíše učit pomalu, krok za krokem (v tom případě si kupte video kurz pro začátečníky na www.bezpecnajoga.cz a přidejte se příští rok)</li>
                <li>Pokud jste raději líná s muffinem na gauči i kolem pasu</li>
                <li>Pokud berete raději po dvou prášky než schody</li>
                <li>Pokud hledáte jen jemnou protahovací jógu, a nechcete se hecnout</li>
                <li>Pokud si myslíte, že k hezké postavě stačí zakoupení permanentky bez poctivé docházky</li>
            </ul>
        </div>

        <p>&nbsp;</p>

        <div>
            <p>JEŠTĚ VÁHÁTE? MRKNĚTE NA VIDEO a na konci si poslechněte chválu našich klientů:</p>
            <figure>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/lKeNq2hYE2Y" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </figure>
        </div>

        <div><a href="https://www.samaya.cz/kurzy/pravidelne" className="btn">JDU DO TOHO! CHCI SI VYBRAT TU PRAVOU LEKCI PRO SEBE!</a></div>

        <p>&nbsp;</p>

        <p>Pokud chcete být součástí naší jógové party, měnit se k lepšímu, žít spokojeněji, víc se sama sobě líbit, pak není proč váhat! Naše kurzy jsou pouze na 10 týdnů, tedy od září do půlky listopadu. Poté si můžete koupit navazující zimní kurz a budete mít přednost před všemi ostatními. Nebo zjistíte, že hledáte něco jiného a my vám poděkujeme a zamáváme na rozloučenou.</p>
        <p>Ukážeme vám, že každá z nás se může dostat do formy, a přitom se bavit.</p>
        <p>Každá lekce je jiná, tedy vás nikdy neomrzí. Sázíme na pestrost, přátelskou atmosféru, hudbu v lekcích a čajík na přivítanou. Nic s sebou nepotřebujete. Dostanete pití, podložku, deku i další pomůcky. (Nicméně stejně si budete chtít pořídit vlastní jógamatku, jakmile józe propadnete, jako my všechny ostatní 😊)</p>

        <figure>
            <img width="564" height="376" src="image007.jpg" alt="Lekce v Samaya.cz" />
        </figure>

        <p>&nbsp;</p>

        <div className="reference">
            <p>Reference:</p>
            <blockquote>Z každé lekce odcházím s povznášejícím pocitem po těle i na duši, po fyzické stránce se cítím mnohem pružnější a pevnější, mé dřívější časté bolesti zad jsou minulostí, po psychické stránce jsem rozhodně vyrovnanější, spokojenější, umím si více vážit a užívat života.<div className="quote-author">Eva Jirkovská</div></blockquote>
            <blockquote>Mé tělo je ohebnější, má mysl zklidněná a po relaxaci mám někdy pocit, že celý svět je v pořádku. Zdravotně jóga prospívá každému svalu, umožňuje uvolnit klouby, zpevní a posílí.<div className="quote-author">Dáša Balvínová</div></blockquote>
            <blockquote>Jméno Míša Levíčková jsem prvně slyšela od své maminky, která si chválila její lekce jógy pro zralé ženy. Sama jsem se pak přihlásila na lekce dynamické jógy. Míša mne nadchla svým osobitým přístupem, zejména mne zaujalo promyšlené provázání pozic s ohledem na roční období a odpovídající orgány a části těla.<div className="quote-author">Lenka Křivánková</div></blockquote>
            <blockquote>Rozhodli jsme se pro soukromé lekce. Individuální péče Míši předčila veškerá naše očekávání. Míša nás začala naprosto nenásilnou formou vtahovat do světa, který zjevně miluje a je v něm mimořádná. Její cvičení posiluje a občas i objevuje pozapomenuté části naší svalové hmoty, přitom se však cítíme naprosto bezpečně.<div className="quote-author">Milan Drbohlav</div></blockquote>
        </div>

        <p>&nbsp;</p>

        <p>Teď už je to jen na vás! Přestaňte váhat a udělejte první krok hned teď.</p>
        <ul>
            <li>děláte závazek jen na 10 týdnů (září-listopad) a pak se rozhodnete, jak dál</li>
            <li>náhrady jsou možné (za omluvené lekce 12h předem)</li>
            <li>permanentka je přenosná a můžete za sebe poslat kohokoli jiného</li>
            <li>cena permanentky je pouze 1600 Kč na 10 lekcí (nebo 2000 Kč u prodlužených lekcí), každá lekce vás tedy vyjde na 160 Kč/ 200 Kč</li>
            <li>nemusíte si kupovat podložku na jógu, je ve studiu pro vás připravená</li>
            <li>ano, bude to výzva – ale zatím to zvládli všichni, kdo měli opravdovou chuť se sebou něco začít dělat. A vydrželi!</li>
            <li>V centru Berouna se večer parkuje bez problémů zdarma kousek od studia, kousek je to k nám i z autobusu/vlaku</li>
        </ul>

        <p>&nbsp;</p>

        <div><a href="https://www.samaya.cz/kurzy/pravidelne" className="btn">CHCI SI VYBRAT TU PRAVOU LEKCI PRO SEBE!</a></div>

        <p>&nbsp;</p>

        <p>Těšíme se na vás!</p>
        <p>Holky lektorky ze Samaya yoga a Life Design studio</p>

    </>
}