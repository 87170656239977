import React, { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Button, ButtonProps } from 'react-bootstrap'
import { BsArrowUpSquare } from 'react-icons/bs'

export const ToTopBtn = ( { ...props }: ButtonProps ) => {

    const intl = useIntl()

    const [ toTopBtnIsVisible, setToTopBtnIsVisible ] = useState( false )

    const onToTopBtnClick = useCallback( () => {
        window.scrollTo({ top: 0 })
    }, [] )

    useEffect( () => {
        const onScroll = () => {
            if( window.scrollY > ( window.innerHeight / 2 ) ) {
                setToTopBtnIsVisible( true )
            } else {
                setToTopBtnIsVisible( false )
            }
        }
        window.addEventListener( 'scroll', onScroll )
        return () => {
            window.removeEventListener( 'scroll', onScroll )
        }
    }, [] )

    return <>{ toTopBtnIsVisible && 
        <Button
            { ...props }
            variant={ props.variant || 'light' }
            title={ intl.formatMessage({ description: 'common.gototop.title', defaultMessage: 'Back to top' }) } 
            onClick={ onToTopBtnClick } 
            style={{ position: 'fixed', bottom: '1.5em', right: '1.5em', zIndex: 1000, lineHeight: 1 }}
        >
            <BsArrowUpSquare size='1.4em' />
        </Button> 
    }</>
}