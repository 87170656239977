import React from 'react'
import { Alert } from 'react-bootstrap'

type AppMessageProps = {
    variant?: string
    message?: string
    children?: React.ReactNode
}

export const AppMessage = ( { variant, message, children }: AppMessageProps ) => {
    return(
        <Alert variant={ variant || 'danger' }>{ children || message }</Alert>
    )
}