import React, { useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Toast, ToastContainer } from 'react-bootstrap'
import { NotificationContext } from '../../contexts/NotificationsContextProvider'

export const NotificationStack = () => {

    const intl = useIntl()

    const notificationContext = useContext( NotificationContext )
    const notifications = notificationContext.getNotifications()

    const headerLabels = useMemo( () => ({
        'info': intl.formatMessage({ description: 'notifications.headerLabel.info', defaultMessage: 'Information' } ),
        'warning': intl.formatMessage({ description: 'notifications.headerLabel.warning', defaultMessage: 'Warning' } ),
        'danger': intl.formatMessage({ description: 'notifications.headerLabel.danger', defaultMessage: 'Error' } ),
        'success': intl.formatMessage({ description: 'notifications.headerLabel.success', defaultMessage: 'Super!' } ),
    }), [ intl ] )
    
    return(<ToastContainer position='top-center'>
        { notifications.map( ( notification ) => 
            <Toast 
                key={ notification.key }
                bg={ notification.variant } 
                onClose={ () => notificationContext.removeNotification( notification.key ) }
                delay={ notification.timeout }
                autohide={ notification.timeout && notification.timeout > 0 ? true : false }
                animation
            >
                <Toast.Header><div className='me-auto'>{ headerLabels[ notification.variant ] }</div></Toast.Header>
                <Toast.Body>{ notification.content }</Toast.Body>
            </Toast>
        ) }
    </ToastContainer>)
}