import { useCallback, useRef, useState } from 'react'

import { download, DownloadProgress } from '../utils/download'
import { AsyncStateActionType } from './useAsync'

const initProgress: DownloadProgress = { loaded: 0, total: 0, percent: 0 }

type UseDownloadProps = {
    url: string
    filename: string
    onFinish?: () => void
}

type UseDownloadState = AsyncStateActionType

type UseDownloadResult = {
    run: ( { url, filename }: UseDownloadProps ) => void
    abort?: () => void
    progress?: DownloadProgress
    status: UseDownloadState
}

export const useDownload = (): UseDownloadResult => {

    const [ progress, setProgress ] = useState<DownloadProgress | undefined>()

    const [ status, setStatus ] = useState<UseDownloadState>( 'init' )

    const abort = useRef<()=>void>()

    const run = useCallback( ( { url, filename, onFinish }: UseDownloadProps ) => {
        // initiliaze progress status
        setProgress({ ...initProgress })
        setStatus( 'run' )
        abort.current = download( 
            url, 
            filename, 
            ( progress ) => setProgress( progress ),
            // clean progress status on end or abort
            ( status ) => {
                setProgress( undefined )
                setStatus( status )
                if( onFinish ) onFinish()
            }
        )
    }, [] )

    return { run, abort: abort.current, progress, status }
}