import React, { createContext, useState } from 'react'
import once from 'lodash/once'

import { ComponentWithChildrenProps } from '../types'

import { Prompt, PromptProps, PromptResult } from '../components/Prompt'

type ModalPromptProps<DataType> = Omit<PromptProps<DataType>, 'show'>

export type ModalPromptContextType = {
    addPrompt: <DataType>( prompt: ModalPromptProps<DataType> ) => void
}

const initModalPromptContent: ModalPromptContextType = {
    addPrompt: <DataType,>() => {
        console.error( 'Error: ModalPromptProvider not initialized')
    }
}
export const ModalPromptContext = createContext<ModalPromptContextType>( initModalPromptContent )

export const ModalPromptProvider = ({ children }: ComponentWithChildrenProps) => {

    const [ prompts, setPrompts ] = useState<PromptProps<any>[]>( [] )

    const addPrompt = <DataType,>( prompt: ModalPromptProps<DataType> ) => {
        setPrompts( [ ...prompts, { 
            ...prompt, 
            show: true,
            onClose: ( result, data ) => {
                setPrompts( prompts.slice( 0, -1 ) )
                if( prompt.onClose ) prompt.onClose( result, data )
            }
        } ] )
    }

    return <ModalPromptContext.Provider value={{ addPrompt }}>
        { prompts.map( ( prompt, index ) => <Prompt key={ index } { ...prompt } /> ) }
        { children }
    </ModalPromptContext.Provider>
}