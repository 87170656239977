import React, { useEffect } from 'react'

import { useQueryString } from '../../../Common'

import { useNotifications } from '../../index'

type NotificationOnPageLoadProps = {
    onloadMessageKey?: string
}

export const NotificationOnPageLoad = ({ onloadMessageKey }: NotificationOnPageLoadProps) => {

    const errorMsg = useQueryString( onloadMessageKey || 'errorMsg' )
    const successMsg = useQueryString( onloadMessageKey || 'successMsg' )

    const { addNotification } = useNotifications()

    useEffect(() => {
        if( errorMsg ) addNotification({ variant: 'danger', content: errorMsg, timeout: 10000 })
        if( successMsg ) addNotification({ variant: 'success', content: successMsg, timeout: 10000 })
    }, [ errorMsg, successMsg ] )

    return <></>
}