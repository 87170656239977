import React, { useCallback, useEffect, useState } from 'react'
import { useReducerWithCallback } from './useReducerWithCallback'

type UseAsyncProps<T> = {
    fn?: { (): Promise<T> } | 'error' | 'success'
    runOn?: React.DependencyList | boolean
    onError?: ( state: AsyncState<T> ) => void
    onSuccess?: ( state: AsyncState<T> ) => void
}

type AsyncRunProps<T> = Omit<UseAsyncProps<T>, 'runOn'>

export type AsyncStateActionType = 'init' | 'run' | 'abort' | 'error' | 'success' | 'finish'

export type AsyncState<T> = {
    phase: AsyncStateActionType
    processing: boolean
    isSuccess?: boolean
    isDone?: boolean 
    message?: string
    error?: unknown
    data?: T
}

type AsyncStateAction<T> = 
    { type: 'init' } 
    | { type: 'run' }
    | { type: 'error', payload: { message: string, error: unknown } }
    | { type: 'success', payload: { message: string, data: T } }
    | { type: 'finish' }

const asynStateReducer = <AppType>( state: AsyncState<AppType>, action: AsyncStateAction<AppType> ): AsyncState<AppType> => {
    switch( action.type ) {
    case 'init':
        return { ...AsyncStateInit() }
    case 'run': 
        return { ...state, phase: action.type, processing: true }
    case 'error':
        return { phase: action.type, processing: false, isSuccess: false, message: action.payload.message, error: action.payload.error, isDone: false }
    case 'success':
        return { phase: action.type, processing: false, isSuccess: true, message: action.payload.message, data: action.payload.data, isDone: false }
    case 'finish':
        return { ...state, isDone: true }
    default:
        throw new Error(`Unhandled action type - ${ JSON.stringify( action ) }`)
    }
}
    
const AsyncStateInit = <T,>(): AsyncState<T> => ({
    phase: 'init',
    processing: false,
})
    
type AsyncReturnType<T> = {
    state: AsyncState<T>
    dispatchState: React.Dispatch<AsyncStateAction<T>>
    run: ( runOptions: AsyncRunProps<T> ) => void
}

export const useAsync = <T>( initOptions: UseAsyncProps<T> ): AsyncReturnType<T> => {

    // persist options to be reuesed in repeated use of hook
    const [ options, setOptions ] = useState<UseAsyncProps<T>>( initOptions )
    const [ state, dispatchState ] = useReducerWithCallback<AsyncState<T>, AsyncStateAction<T>>( asynStateReducer, AsyncStateInit() )

    const runAsync = useCallback( async ( runOptions?: AsyncRunProps<T> ) => {
        const calcOptions = { ...options, ...runOptions }
        // if there is no fn - error
        if( !options.fn && !runOptions?.fn ) throw new Error( 'Async function that shoudl be run is not defined.' )
        setOptions( calcOptions )
        dispatchState({ type: 'run' })

        const reducerCallback = ( state: AsyncState<T> ) => {
            if( state.phase === 'success' ) calcOptions.onSuccess?.( state )
            else if( state.phase === 'error' ) calcOptions.onError?.( state ) 
            dispatchState({ type: 'finish' }) 
        }

        if( typeof calcOptions.fn === 'function' ) {
            try {
                const result = await calcOptions.fn()
                dispatchState({ type: 'success', payload: { message: 'OK', data: result } }, reducerCallback )
            } catch( error: unknown ) {
                console.log( error )
                dispatchState({ type: 'error', payload: { message: String( error ), error } }, reducerCallback )
            }
        } 
        else {
            if( calcOptions.fn === 'error' ) {
                dispatchState({ type: 'error', payload: { message: 'Unknwon error', error: new Error( 'Unknwon error' ) } }, reducerCallback )
            }
            else if( calcOptions.fn === 'success' ) dispatchState({ type: 'success', payload: { message: 'OK', data: {} as T } }, reducerCallback )
        }
    // dispatchState is guaranteed to be persistent (feature of the useReducerWithCallback hook)
    }, [ state, options ] )

    const run = ( options: AsyncRunProps<T> ) => {
        // run only if not yet running
        if( !state.processing ) void runAsync( options )
    }

    // automatic run effect
    // run immediatelly in case runOn is true or there are some dependencies defined
    useEffect( () => {
        if( !state.processing && options.runOn ) void runAsync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, typeof options.runOn === 'undefined' || typeof options.runOn === 'boolean' ? [] : options.runOn )

    return { state, dispatchState, run }
}