import React from 'react'
import loadable from '@loadable/component'

export { FormContextType } from './types'
export { Form } from './components/Form'
export { Text } from './components/Text'
export { TextArea } from './components/TextArea'
export { Password } from './components/Password'
export { Combo } from './components/Combo'
export { CheckBox } from './components/CheckBox'
export { Countries } from './components/Countries'
export { DatePicker } from './components/DatePicker'
export { Attachments } from './components/Attachments'
export { Monaco } from './components/Monaco'
export { useForm } from './hooks/useForm'
export { useUrlFrom } from './hooks/useUrlFrom'

const RichText = loadable( async () => {
    const { RichText } = await import( './components/RichText' ) 
    return RichText
}, { ssr: false } )
export { RichText }
