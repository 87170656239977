import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

type ExpandableProps = {
    content: string
    defaultState?: boolean
}

export const Expandable = ( { content, defaultState = false }: ExpandableProps ) => {
    
    const [ isExpanded, setIsExpanded ] = useState( defaultState )

    return(<>
        <small className={ isExpanded ? 'more' : '' } dangerouslySetInnerHTML={{ __html: content }} />
        <div className="text-right toggle">
            <a onClick={ () => setIsExpanded( !isExpanded )}>
                <span>{ isExpanded 
                    ?  <FormattedMessage description='tlačítko méně' defaultMessage='méně' /> 
                    : <FormattedMessage description='tlačítko více' defaultMessage='více' /> 
                }</span>&nbsp;
                <span className={ isExpanded ? 'caret-up' : 'caret-down' }></span>
            </a>
        </div>
    </>)
}