import { Notification, NotificationList } from '../types'

let notifications: NotificationList = []

const getNotifications = () => notifications

const addNotification = ( notification: Notification ) => { notifications.push({ ...notification, category: notification.category || 'general', key: new Date().getTime() }) }

const removeNotification = ( key: number ) => notifications = notifications.filter( notification => notification.key !== key )

const removeAllNotifications = ( category?: string ) => notifications = category ? notifications.filter( n => n.category !== category ) : []

export const NotificationService = { getNotifications, addNotification, removeNotification, removeAllNotifications }