import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Col, Row } from 'react-bootstrap'

import { useAsync, useChange } from '../../../Common'
import { Form, FormContextType, Text } from '../../../Forms'

import { API_URL as API_BASE } from '../../../App/AppConfig'
import { AppPageHeading } from '../../../App/components/AppPageHeading'
import { AppStatus } from '../../../App/components/AppStatus'

import { Event as EventModel } from '../../../../../cz.samaya.api/src/_api-models/index'
import { CoursesService } from '../../services/courses'

import kurzy from '../../../assets/img/kurzy.jpg?format=webp'
import { Course } from '../Courses/Course'

export const Order = () => {

    const intl = useIntl()

    const navigate = useNavigate()

    const { courseId } = useParams()
    if( ! courseId ) navigate( '/kurzy/pravidelne', { replace: true } )
    const [ course, setCourse ] = useState<EventModel | null>( null )
    const [ fullyBooked, setFullyBooked ] = useState( false )
    const [ directReservation, setDirectReservation ] = useState( false )

    const emailRef = useRef<HTMLInputElement>( null )

    const service = useMemo( () => new CoursesService(), [] )

    const errorFetchMsg = useMemo( () => intl.formatMessage({ description: 'OrderNoCourseData', defaultMessage: 'Omlouvám se, ale něco se nezdařilo a nelze načíst data o kurzu. Prosím, zavolejte mi na +420 605583469.' }), [ intl ] )
    const errorSendMsg = useMemo( () => intl.formatMessage({ description: 'OrderSubmitError', defaultMessage: 'Vaši rezervaci se nepodařilo zaregistrovat. Zkuste to prosím později nebo mi zavolejte na +420 605583469.' }), [ intl ] )

    const fetchCourse = useAsync({
        // if no courseId is provided component will navigate elsewhere - here it is no option
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        fn: async () => await service.getAll( { key: courseId!, filter: 'currentAndFuture' } ),
        runOn: true,
        onSuccess: state => {
            if( !state.data || state.data.length !== 1 ) {
                navigate( '/kurzy/pravidelne', { replace: true, state: { message: errorFetchMsg } } )
            } else {
                setCourse( state.data[ 0 ] )
                if( state.data[ 0 ].ext_data?.fullyBooked ) setFullyBooked( true )
                if( state.data[ 0 ].lectorId === 70 ) setDirectReservation( true )
            }
        },
        onError: () => {
            fetchCourse.dispatchState({ type: 'error', payload: { message: errorFetchMsg, error: '' } })
        }
    })
    const sendOrder = useChange({ 
        url: API_BASE + '/order/confirm',
        method: 'POST',
        onError: state => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            sendOrder.dispatchState({ type: 'error', payload: { status: state.status!, message: errorSendMsg } })
        }
    })

    const onFinish = ( form: FormContextType ) => {
        sendOrder.run({ data: { ...course, courseName: course?.name, email: form.data.email, name: form.data.name, phone: form.data.phone } })
    }

    useEffect( () => {
        emailRef.current?.focus()
    }, [] )

    return(<>
        <AppPageHeading imgUrl={ kurzy.src } title={ intl.formatMessage({ description: 'Kurzy rezervace nadpis', defaultMessage: 'Rezervace kurzu' }) } />
        <AppStatus 
            error={ sendOrder.state.phase === 'error' ? sendOrder.state.message || '' : '' } 
            loading={ fetchCourse.state.processing || sendOrder.state.processing } 
        />
        { sendOrder.state.phase === 'finish' && <div>Děkuji vám za vaši rezervaci. Situace se velice rychle mění, proto ještě ověřím dostupné místo na kurzu a rezervaci vám emailem nebo telefonicky potvrdím. Pěkný den! Míša</div> }
        { course && sendOrder.state.phase !== 'finish' &&
        <Row>
            <Course row={ course } allowReservation={ false }/>
            <Col className='my-3'>
                { fullyBooked && <div>
                    <strong>Omlouvám se, ale tento kurz je již plně rezervován a další rezervace nepřijímáme. Zkuste si prosím <Link to='/kurzy/pravidelne' replace>vybrat jiný kurz.</Link></strong>
                </div> }
                { ! fullyBooked && directReservation && <div>
                    <strong>Omlouvám se, ale tento kurz je nutné si rezervovat přímo u lektorky. Kontakt je uveden v popisu kurzu.</strong>
                </div> }
                { ! fullyBooked && ! directReservation && <Form 
                    formName='course-order'
                    initialData={{
                        email: '',
                        name: '',
                        phone: ''
                    }}
                    onFinishCallback={ onFinish }
                >
                    <div className='my-2'><strong><FormattedMessage description='' defaultMessage='Závazně si rezervuji kurz {courseName}.' values={{ courseName: course?.name }} /></strong></div>
                    <Text 
                        controlId='email' 
                        ref={ emailRef }
                        type='email' 
                        className="mb-3"
                        required
                        placeholder={ intl.formatMessage({ description: 'Email', defaultMessage: 'Email' }) }
                        invalidMsg={ intl.formatMessage({ description: 'Email is required', defaultMessage: 'Email is required' }) }
                    />
                    <Text 
                        controlId='name' 
                        className="mb-3"
                        required
                        placeholder={ intl.formatMessage({ description: 'Name', defaultMessage: 'Name' }) }
                        invalidMsg={ intl.formatMessage({ description: 'Name is required', defaultMessage: 'Name is required' }) }
                    />
                    <Text 
                        controlId='phone'
                        type='phone'
                        className="mb-3"
                        required
                        placeholder={ intl.formatMessage({ description: 'Phone', defaultMessage: 'Phone' }) }
                        invalidMsg={ intl.formatMessage({ description: 'Phone is required', defaultMessage: 'Phone is required' }) }
                    />
                    <Button type='submit' className='order-form-button'>
                        <FormattedMessage description='order-submit' defaultMessage='Odeslat rezervaci' />
                    </Button>
                </Form> }
            </Col>
        </Row> }
    </>)
}