import React, { useEffect, useState } from 'react'
import { FormattedDate, useIntl } from 'react-intl'
import { Row } from 'react-bootstrap'

import DatePicker, { registerLocale } from 'react-datepicker'
import { cs } from 'date-fns/locale'
registerLocale( 'cs', cs )

import { AppPageHeading } from '../../../App/components/AppPageHeading'
import { AppStatus } from '../../../App/components/AppStatus'

import { Block } from '../Block'

import { CoursesCalService  } from '../../services/courses-cal'
import { EventCal as EventCalModel } from '../../../../../cz.samaya.api/src/_api-models/index'
import { CourseCal } from './CourseCal'

import rozvrh from '../../../assets/img/rozvrh.jpg?format=webp'
import { DAYS } from '../Courses/Course'

const MSDAY = (24 * 60 * 60 * 1000)

const calcStartDate = ( today: Date ) => {
    const wd = today.getDay()
    const startDt = today.getTime() + ( -wd + ( wd === 0 ? -6 : 1 ) ) * MSDAY
    return startDt
}

export const CoursesCal = () => {

    const intl = useIntl()

    const currentDt = new Date(), sept2022 = new Date( '2022-09-05' )
    const minDate = currentDt < sept2022 ? sept2022 : currentDt

    const [ error, setError ] = useState( '' )
    const [ loading, setLoading ] = useState( true )
    const [ rows, setRows ] = useState<EventCalModel[][] | null>( null )

    const [ calendarIsVisible, setCalendarIsVisible ] = useState( false )
    const [ startDt, setStartDt ] = useState<number>( minDate.getTime() )

    useEffect( () => {
        const getRows = async () => {
            try {
                const service = new CoursesCalService()
                const rows = await service.getAll( 
                    {
                        key: (new Date( startDt )).toISOString().split( 'T' )[ 0 ],
                        where: { $and: [ { dt: { $gte: new Date( startDt ) } }, { dt: { $lte: new Date( startDt + ( 6 * MSDAY ) ) } } ] },
                        order: { dt: 'ASC' as const, start: 'ASC' as const },
                    }
                )
                const days: EventCalModel[][] = Array.from( Array( 7 ), () => [] )

                // split list into days
                rows.forEach( c => { 
                    if( !c.start ) return
                    let d = c.dt?.getDay()
                    d = !d ? 7 : d // move sunday at the end - making it one-based list
                    days[ d - 1 ].push( c )
                } )

                // order each day by time
                days.forEach( day => {
                    day.sort( ( a, b ) => {
                        const at: number = a.start?.getHours() || 0
                        const bt: number = b.start?.getHours() || 0
                        return at < bt ? -1 : 1
                    } )
                } )

                setRows( days )
                setLoading( false )
            } catch( err: unknown ) {
                // eslint-disable-next-line @typescript-eslint/no-base-to-string
                if( typeof err === 'object' && typeof err?.toString === 'function' ) setError( err.toString() )
                setLoading( false )
            }
        }
        void getRows()
    }, [ startDt ] )

    return(<>
        <AppPageHeading imgUrl={ rozvrh.src } title={ intl.formatMessage({ description: 'Rozvrh nadpis', defaultMessage: 'Rozvrh' }) } />
        <AppStatus error={ error } loading={ loading } />
        <div className="m-4">
            Rozvrh pro týden od <FormattedDate value={ new Date( startDt ) } /> do <FormattedDate value={ new Date( startDt + ( 6 * MSDAY ) ) } />. Vybrat jiné datum:
            <button type="button" className="btn" onClick={ () => setCalendarIsVisible( ! calendarIsVisible ) }>
                <i className="glyphicons glyphicons-calendar" aria-hidden="true"></i>
            </button>
            <div className="calendar">
                { calendarIsVisible && <DatePicker
                    locale='cs'
                    minDate={ minDate }
                    showWeekNumbers
                    selected={ new Date( startDt ) }
                    onChange={ val => { setCalendarIsVisible( false ); setStartDt( calcStartDate( val || new Date() ) ) } }
                    inline
                /> }
            </div>
        </div>
        <Row>
            { DAYS.map( ( dayName, dayIndex ) =><Block key={ dayIndex } title={ dayName }>
                { rows && rows.length > 0 && rows[ dayIndex ].map( r => <CourseCal key={ r.eventId } row={ r } />) }
            </Block>) }
        </Row>
    </>)
}