import { AuthData, JwtDecodedToken, User } from '../types'

import { Storage, LocalStorage as defaultStorage } from '../../Common'

// decode the logged in user
export const parseJwt = ( token: string  ): JwtDecodedToken => {
    const base64Url = token.split( '.' )[ 1 ]
    const base64 = base64Url.replace( '-', '+' ).replace( '_', '/') 
    return JSON.parse( window.atob( base64 ) ) as JwtDecodedToken
}

export const authDataStorageFactory = ( storage: Storage = defaultStorage ) => ({
    setPersistentAuthData: <T extends User>( authData: AuthData<T> ) => storage.setItem( 'AuthContext', JSON.stringify( authData ) ),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    getPersistentAuthData: <T extends User>(): AuthData<T> | undefined => JSON.parse( storage.getItem( 'AuthContext' ) || 'null' ) || undefined,
    removePersistentAuthData: (): void => storage.removeItem( 'AuthContext' )
})
