import React, { createContext, useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button, Modal } from 'react-bootstrap'

import { PromptContextType } from '../../types'

type PromptType = 'OKCancel' | 'YesNo'

export type PromptResult = 'OKYes' | 'CancelNo'

export type PromptProps<DataType> = {
    heading: string
    message: string | React.ReactNode
    size?: 'sm' | 'lg' | 'xl' 
    type?: PromptType
    focus?: PromptResult
    show: boolean
    initialData?: DataType
    onClose: ( closeBtn: PromptResult, data?: DataType ) => void
}

export const PromptContext = <DataType,>() => createContext<PromptContextType<DataType> | undefined>( undefined )

export const Prompt = <DataType,>( { heading, message, size, type = 'YesNo', focus = 'CancelNo', show, initialData, onClose }: PromptProps<DataType> ) => {

    const handleOKYes = () => {
        onClose( 'OKYes', data )
    }

    const handleCancelNo = () => {
        onClose( 'CancelNo' )
    }

    const [ data, setData ] = useState<DataType>( initialData as DataType )
    const setDataCallback = useCallback( ( value: DataType ) => {
        setData({ ...data, ...value })
    }, [ data, setData ] )

    const Context = PromptContext<DataType>()

    const contextValue = () => ({ data, setData: setDataCallback })

    return <Context.Provider value={ contextValue() }>
        <Modal
            show={ show }
            onHide={ handleCancelNo }
            backdrop='static'
            keyboard={ false }
            size={ size }
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{ heading }</Modal.Title>
            </Modal.Header>
            <Modal.Body>{ message }</Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={ handleOKYes } autoFocus={ focus === 'OKYes' } >
                    { type === 'OKCancel' && <FormattedMessage description='common.prompt.OK' defaultMessage='OK' /> }
                    { type === 'YesNo' && <FormattedMessage description='common.prompt.Yes' defaultMessage='Yes' /> }
                </Button>
                <Button variant="secondary" onClick={ handleCancelNo } autoFocus={ focus === 'CancelNo' } >
                    { type === 'OKCancel' && <FormattedMessage description='common.prompt.Cancel' defaultMessage='Cancel' /> }
                    { type === 'YesNo' && <FormattedMessage description='common.prompt.No' defaultMessage='No' /> }
                </Button>
            </Modal.Footer>
        </Modal>
    </Context.Provider>
}
