import React, { useEffect, useState } from 'react'
import { GTMProvider as GTMProviderOrig } from '@elgorditosalsero/react-gtm-hook'
import { ISnippetsParams } from '@elgorditosalsero/react-gtm-hook/dist/models/GoogleTagManager'
import { useCookieConsentContext } from '@use-cookie-consent/react'

import { ComponentWithChildrenProps } from '../../../Common'

type GTMProviderProps = ComponentWithChildrenProps & {
    state: ISnippetsParams
}

export const GTMProvider = ( { state, children }: GTMProviderProps ) => {

    const { consent, cookies } = useCookieConsentContext()

    const [ useGTM, setUseGTM ] = useState( consent.statistics )

    // when condition was disapproved - remove script!
    useEffect( () => {
        if( useGTM && !consent.statistics ) {
            // eslint-disable-next-line no-self-assign
            window.location.reload()
        }
        else if( !useGTM && consent.statistics ) setUseGTM( true )
        // in case loaded (initially) and useGTM is forbiden - clear cookies
        // we do it on init as far as reload triggers pageView and this generates cookie
        // therefore deleting prior reload will still keep one cookie
        if( !useGTM && cookies ) {
            const allCookies = cookies.getAll()
            Object.keys( allCookies ).forEach( cookie => { if( cookie.startsWith( '_ga' ) ) cookies.remove( cookie, { path: '/', domain: '.vegilove.cz' } ) } )
        }
    }, [ consent ])

    return <>{ useGTM
        ? <GTMProviderOrig state={ state }>{ children }</GTMProviderOrig>
        : <>{ children }</>
    }</>
}
